import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";


import { useOutletContext, Link } from "react-router-dom";

import './mHalfs.scss';

const MHalfsAdd = () => {
  const {
    priceForOne,
    setAddToAll,
    setAddToFirst,
    setAddToSecond,
    addToAll,
    countOfExtras,
    extraIngr,
    setExtraIngr,
    addToFirst,
    namePoloOne,
    namePoloTwo,
    extraIngrFirst,
    extraIngrSecond,
    setExtraIngrSecond,
    setExtraIngrFirst,
    addToSecond,
  } = useOutletContext();

  return (
    <div
      className="m-modal"
    >
      <div className="m-halfs">
        <Link to={'/halfs'}><div className="m-modal__close">
          ⟵
        </div>
        </Link>

        <div className="modal__pizza-additionally-ingr">
          Додати інгредієнти ↓
        </div>
        <div className="halfs__where-add-wrapper">
          <span
            onClick={() => {
              setAddToAll(true);
              setAddToFirst(false);
              setAddToSecond(false);
            }}
            className={addToAll ? "halfs__where-add-act" : ""}
          >
            до всієї піци
            {countOfExtras(extraIngr) !== 0
              ? ` (${countOfExtras(extraIngr)})`
              : null}
          </span>
          <span
            onClick={() => {
              setAddToAll(false);
              setAddToFirst(true);
              setAddToSecond(false);
            }}
            className={addToFirst ? "halfs__where-add-act" : ""}
          >
            до "{namePoloOne.replace('поло-', '')}"
            {countOfExtras(extraIngrFirst) !== 0
              ? ` (${countOfExtras(extraIngrFirst)})`
              : null}
          </span>
          <span
            onClick={() => {
              setAddToAll(false);
              setAddToFirst(false);
              setAddToSecond(true);
            }}
            className={addToSecond ? "halfs__where-add-act" : ""}
          >
            до "{namePoloTwo.replace('поло-', '')}"
            {countOfExtras(extraIngrSecond) !== 0
              ? ` (${countOfExtras(extraIngrSecond)})`
              : null}
          </span>
        </div>
        {addToAll ? (
          <AddIngredients extraIngr={extraIngr} setExtraIngr={setExtraIngr} polo={true} setExtraMore={setExtraIngrFirst} setExtraMore2={setExtraIngrSecond} priceForOne={priceForOne} />
        ) : null}
        {addToFirst ? (
          <AddIngredients extraIngr={extraIngrFirst} setExtraIngr={setExtraIngrFirst} polo={true} setExtraMore={setExtraIngr} setExtraMore2={setExtraIngrSecond} priceForOne={priceForOne} />
        ) : null}
        {addToSecond ? (
          <AddIngredients extraIngr={extraIngrSecond} setExtraIngr={setExtraIngrSecond} polo={true} setExtraMore={setExtraIngr} setExtraMore2={setExtraIngrFirst} priceForOne={priceForOne} />
        ) : null}


      </div>
    </div>
  );
};

export default MHalfsAdd;

import ChosenBorts from "../../little/ChosenBorts";
import DescrElemWithDeleteFunc from "../../mod-pizza/delete-ingredients/DescrElemWithDeleteFunc";
import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import ListOfAddIngrWithDeleteFunc from "../../mod-pizza/add-ingredients/ListOfAddIngrWithDeleteFunc";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";
import ImgWithPreloadSpinner from "../../little/ImgWithPreloadSpinner";

import { useNavigate } from "react-router-dom";

const PCHalfs = ({ propses }) => {
  const {
    ref,
    refForClickOutside1,
    showFirstPolo,
    setShowFirstPolo,
    firstPolo,
    firstPizzasPolo,
    refForClickOutside2,
    showSecondPolo,
    setShowSecondPolo,
    secondPolo,
    secondPizzasPolo,
    borts,
    setBortIndex,
    bortIndex,
    ingr,
    deletedIngrForOne,
    deletedIngrForTwo,
    setDeletedIngrForOne,
    setDeletedIngrForTwo,
    setAddToAll,
    setAddToFirst,
    setAddToSecond,
    addToAll,
    countOfExtras,
    extraIngr,
    setExtraIngr,
    addToFirst,
    namePoloOne,
    namePoloTwo,
    extraIngrFirst,
    extraIngrSecond,
    setExtraIngrSecond,
    setExtraIngrFirst,
    addToSecond,
    modPrice,
    countModPizza,
    setCountModPizza,
    fullId,
    firstPoloImg,
    secondPoloImg,
  } = propses;

  const navigate = useNavigate();

  return (
    <div
      className="modal"
      ref={ref}
      onClick={(e) => {
        if (e.target === ref.current) navigate("/");
      }}
    >
      <div className="halfs">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="m-modal__close"
        >
          ╳
        </div>
        {/* начинается первая колонка */}
        <div className="halfs__first-col">
          <div className="halfs__cost">Вартість послуги поєднання - 10 грн</div>
          <div className="halfs__wrap">
            <div style={{ position: "relative" }}>
              <span
                ref={refForClickOutside1}
                onClick={() => {
                  setShowFirstPolo(!showFirstPolo);
                }}
                className="halfs__polo-name"
              >
                {firstPolo.name} {showFirstPolo ? "▲" : "▼"}
              </span>

              {showFirstPolo ? (
                <ul className="halfs__polo-ul-first">{firstPizzasPolo}</ul>
              ) : null}
              <ImgWithPreloadSpinner src={firstPolo.img} alt="half-of-pizza"
                className="halfs__polo-img halfs__polo-img-left" />
            </div>

            <div style={{ position: "relative" }}>
              <span
                ref={refForClickOutside2}
                onClick={() => {
                  setShowSecondPolo(!showSecondPolo);
                }}
                className="halfs__polo-name"
              >
                {secondPolo.name} {showSecondPolo ? "▲" : "▼"}
              </span>

              {showSecondPolo ? (
                <ul className="halfs__polo-ul-second">{secondPizzasPolo}</ul>
              ) : null}
              <ImgWithPreloadSpinner src={secondPolo.img} alt="half-of-pizza"
                className="halfs__polo-img halfs__polo-img-right" />
            </div>
          </div>
          <ChosenBorts
            borts={borts}
            bortIndex={bortIndex}
            setBortIndex={setBortIndex}
          />
          <div className="halfs__first-col-wrap-for-add">
            {Object.keys(extraIngr).length ? (
              <>
                <div className="halfs__added-elem-title">
                  Додано до всієї піци:
                </div>
                <ul className="modal__pizza-extra">
                  <ListOfAddIngrWithDeleteFunc
                    ingr={ingr}
                    extraIngr={extraIngr}
                    setExtraIngr={setExtraIngr}
                  />
                </ul>

              </>
            ) : null}
            {Object.keys(extraIngrFirst).length ? (
              <>
                <div className="halfs__added-elem-title">
                  Додано до "{namePoloOne}":
                </div>
                <ul className="modal__pizza-extra">
                  <ListOfAddIngrWithDeleteFunc
                    ingr={ingr}
                    extraIngr={extraIngrFirst}
                    setExtraIngr={setExtraIngrFirst}
                  />
                </ul>
              </>
            ) : null}
            {Object.keys(extraIngrSecond).length ? (
              <>
                <div className="halfs__added-elem-title">
                  Додано до "{namePoloTwo}":
                </div>
                <ul className="modal__pizza-extra">
                  <ListOfAddIngrWithDeleteFunc
                    ingr={ingr}
                    extraIngr={extraIngrSecond}
                    setExtraIngr={setExtraIngrSecond}
                  />
                </ul>
              </>
            ) : null}
          </div>
        </div>
        {/* начинается вторая колонка */}
        <div className="halfs__second-col">
          <span className="halfs__descrFirstPolo-title">
            Cклад {firstPolo.name}:
          </span>
          <ul className="halfs__descrFirstPolo">
            <DescrElemWithDeleteFunc
              descr={firstPolo.descr}
              ingr={ingr}
              deletedIngr={deletedIngrForOne}
              setDeletedIngr={setDeletedIngrForOne}
            />
          </ul>
          <span className="halfs__descrSecondPolo-title">
            Cклад {secondPolo.name}:
          </span>
          <ul className="halfs__descrSecondPolo">
            <DescrElemWithDeleteFunc
              descr={secondPolo.descr}
              ingr={ingr}
              deletedIngr={deletedIngrForTwo}
              setDeletedIngr={setDeletedIngrForTwo}
            />
          </ul>

          <div className="halfs__apart-block-toadd">
            <div className="modal__pizza-additionally-ingr">
              Додати інгредієнти ↓
            </div>
            <div className="halfs__where-add-wrapper">
              <span
                onClick={() => {
                  setAddToAll(true);
                  setAddToFirst(false);
                  setAddToSecond(false);
                }}
                className={addToAll ? "halfs__where-add-act" : ""}
              >
                до всієї піци
                {countOfExtras(extraIngr) !== 0
                  ? ` (${countOfExtras(extraIngr)})`
                  : null}
              </span>
              <span
                onClick={() => {
                  setAddToAll(false);
                  setAddToFirst(true);
                  setAddToSecond(false);
                }}
                className={addToFirst ? "halfs__where-add-act" : ""}
              >
                до {namePoloOne.replace('поло-', '')}
                {countOfExtras(extraIngrFirst) !== 0
                  ? ` (${countOfExtras(extraIngrFirst)})`
                  : null}
              </span>
              <span
                onClick={() => {
                  setAddToAll(false);
                  setAddToFirst(false);
                  setAddToSecond(true);
                }}
                className={addToSecond ? "halfs__where-add-act" : ""}
              >
                до {namePoloTwo.replace('поло-', '')}
                {countOfExtras(extraIngrSecond) !== 0
                  ? ` (${countOfExtras(extraIngrSecond)})`
                  : null}
              </span>
            </div>
            {addToAll ? (
              <AddIngredients extraIngr={extraIngr} setExtraIngr={setExtraIngr} />
            ) : null}
            {addToFirst ? (
              <AddIngredients
                extraIngr={extraIngrFirst}
                setExtraIngr={setExtraIngrFirst}
              />
            ) : null}
            {addToSecond ? (
              <AddIngredients
                extraIngr={extraIngrSecond}
                setExtraIngr={setExtraIngrSecond}
              />
            ) : null}
          </div>



          <BlockWithPriceCountCart
            polo={true}
            modPrice={modPrice}
            countModPizza={countModPizza}
            setCountModPizza={setCountModPizza}
            fullId={fullId}
            borts={borts}
            bortIndex={bortIndex}
            deletedIngr={{ deletedIngrForOne, deletedIngrForTwo }}
            name={{ namePoloOne, namePoloTwo }}
            img={{ firstPoloImg, secondPoloImg }}
            extraIngr={{ extraIngr, extraIngrFirst, extraIngrSecond }}
          />
        </div>
      </div>
    </div>
  );
};

export default PCHalfs;

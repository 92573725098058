
const ChooseVarietiesForDrinks = ({ varieties, currentVarietiesIndex, setCurrentVarietiesIndex }) => {

  return (
    <div className="drinkblock__choose">
      {Object.keys(varieties).map((item) => {
        return (
          <span
            className={
              item === currentVarietiesIndex
                ? "modal__borts-item modal__borts-item-act"
                : "modal__borts-item"
            }
            key={item}
            onClick={() => {
              setCurrentVarietiesIndex(item);
            }}
          >
            {varieties[item].volume} л
          </span>
        );
      })}
    </div>
  );
}

export default ChooseVarietiesForDrinks;

import {useRef, useState, useEffect} from 'react';
import one from '../../../../assets/n1.png';
import two from '../../../../assets/n2.png';
import three from '../../../../assets/n3.png';

import './zones.scss'


const Zones = ({zones, chosenZone, setChosenZone, setIsZone})=>{

    const ref = useRef(null);
 
    const clickOut = (e)=>{
        if(ref.current && !ref.current.contains(e.target)){
            setIsZone(false);
        }
    }
    useEffect(()=>{
        document.addEventListener('click', clickOut, {passive: true});
        return ()=>{document.removeEventListener('click', clickOut, {passive: true});}
    }, []);

    return(
        <div className="zones">
            <div className="zones__wrapper" 
                ref={ref}>
            
            <div className="zones-content">
                <div className="zones-content__close"
                onClick={()=>{
                    setIsZone(false);
                }}
                >╳</div>
                <img className="zones-pic" src={one} alt="img" />
                <img className={chosenZone === 'a' ? 'zones-pic' : 'zones-pic zones-non-active'} src={two} alt="img"/>
                <img className={chosenZone === 'a' ? 'zones-pic  zones-non-active' : 'zones-pic'} src={three} alt="img" />
            </div> 
            <div className="zones__footer">Обрати район міста ↓</div> 
            <div className="zones__choose">
                <span className={chosenZone === 'a' ? 'zones__choose-act' : ''}
                onClick={()=>{
                    setChosenZone('a');
                }}
                >{zones['a'][0]}</span>
                <span className={chosenZone === 'a' ? '' : 'zones__choose-act'}
                onClick={()=>{
                    setChosenZone('b');
                }}
                >{zones['b'][0]}</span>
            </div>
            <div
            onClick={()=>{
                setIsZone(false);
            }} 
            className="zones__ok">OK</div> 
            </div>
         
        </div>
    )
}

export default Zones;

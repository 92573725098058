import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { goRedirect } from '../../../features/generalSlice';
import { resetAllCart } from '../../../features/cartSlice';

import { refOnServer } from '../../../data-names/data';

import FormClarifyPay from './FormClarifyPay';

const ButtonPay = ({ namer, phone, type, city, chosenCity, street, house,
  aprts, entrance, code, floor, isAsFast, customDate, wishCall,
  comments, totalPricePlusDelivery, arrOfPizzas, zones, chosenZone, totalPrice, messageToTgWithPromo, fetchToUpdateUsedPromo, specialPromoPrice }) => {

  const [data, setData] = useState('');
  const [signature, setSignature] = useState('');
  const [error, setError] = useState(false);


  const dispatch = useDispatch();
  const redirect = useSelector(state => state.general.redirect);

  const messageToTelegram = {
    parse_mode: 'HTML',
    text: type === 'Доставка' ?
      `<b>Клієнт:</b> ${namer}\n<b>Телефон:</b> +${phone}\n\n■ <b>${type.toUpperCase()} в ${chosenCity !== '0' ? city[chosenCity].city : city[chosenCity].city + ' (район ' + zones[chosenZone][0] + ')'}</b> за <b>адресою:</b> ${street}, <b>будинок:</b> ${house}${aprts ? `<b>, квартира:</b> ${aprts}` : ''}${entrance ? `<b>, під'їзд:</b> ${entrance}` : ''}${code ? `<b>, код:</b> ${code}` : ''}${floor ? `<b>, поверх:</b> ${floor}` : ''} ${isAsFast ? `\n\n■ <b>Якомога скоріше, будьте ласкаві.</b>` : customDate ? `\n\n■ <b>Замовлення на певний час:</b> ${customDate}` : ``} ${wishCall ? `` : '\n\n■ <b>Просять не передзвонювати.</b>'} ${comments ? `\n\n■ <b>Коментар до замовлення:</b> ${comments}` : ''} \n\n✪ <b>ЗАГАЛЬНА СУМА замовлення:</b> ${totalPricePlusDelivery} грн, з яких за доставку ${chosenCity === '0' ? zones[chosenZone][1] + ' грн' : [chosenCity].price + ' грн'} ${messageToTgWithPromo} ${arrOfPizzas} \n\n<b>ЧЕКАЄМО НА ПІДТВЕРДЖЕННЯ ОПЛАТИ!</b>`
      :
      `<b>Клієнт:</b> ${namer}\n<b>Телефон:</b> +${phone}\n\n■ <b>${type.toUpperCase()}</b>${isAsFast ? `\n\n■ <b>Якомога скоріше, будьте ласкаві.</b>` : customDate ? `\n\n■ <b>Замовлення на певний час:</b> ${customDate}` : ``} ${wishCall ? `` : '\n\n■ <b>Просять не передзвонювати</b>'} ${comments ? `\n\n■ <b>Коментар до замовлення:</b> ${comments}` : ''} \n\n✪ <b>ЗАГАЛЬНА СУМА замовлення:</b> ${totalPrice} грн ${messageToTgWithPromo} ${arrOfPizzas} \n\n<b>ЧЕКАЄМО НА ПІДТВЕРДЖЕННЯ ОПЛАТИ!</b>`,
    chat_id: "-1001732405733",

  }
  const fetchingToTelegram = async () => {
    return await fetch('https://api.telegram.org/bot5680266305:AAFXGN0g8IP2HhBkCWnqH3EDqnHcgX_KCEg/sendMessage', {
      method: 'POST',
      body: JSON.stringify(messageToTelegram),
      headers: {
        'Content-Type': 'application/json'
      }
    })

  }


  //ниже данные для работы с сервером и получения хеша
  const objToServer = { totalPrice: specialPromoPrice ?? totalPricePlusDelivery ?? totalPrice };
  const fetchingForHash = async () => {
    return await fetch(`${refOnServer}/hash`, {
      method: 'POST',
      body: JSON.stringify(objToServer)
    })
  }
  //работа с сервером окончена

  return (
    <>
      <button className="order__submit-btn"
        onClick={(e) => {
          e.preventDefault();

          if (type === 'Доставка') {
            //отправляем данные в локалСторэйдж
            localStorage.setItem('zone', chosenZone);
            localStorage.setItem('phone', phone);
            localStorage.setItem('namer', namer);
            localStorage.setItem('street', street);
            localStorage.setItem('house', house);
            localStorage.setItem('aprts', aprts);
            localStorage.setItem('city', chosenCity)
            //самая простая проверка на наличие обязательных полей
            if (namer.length === 0 || phone.length === 0 || street.length === 0 || house.length === 0) {
              alert("Будь ласка, заповніть усі обов'язкові поля (ім'я, номер, вулиця, будинок), інакше замовлення не буде опрацьовано.");
              return
            }
          } else {
            //отправляем данные в локалСторэйдж
            localStorage.setItem('phone', phone);
            localStorage.setItem('namer', namer);
            //самая простая проверка на наличие обязательных полей
            if (namer.length === 0 || phone.length === 0) {
              alert("Будь ласка, заповніть усі обов'язкові поля (ім'я, номер), інакше замовлення не буде опрацьовано.");
              return
            }
          }

          fetchingForHash()
            .catch(error => {
              console.log(error);
              setError(true)
            })
            .then(res => {
              if (!res.ok) {
                setError(true);
                return;
              }
              return res
            })
            .then(res => res.json())
            .then(res => {
              setData(res.data);
              setSignature(res.signature);
              dispatch(goRedirect(true));
              window.scrollTo({
                top: 0,
                lef: 0,
                behavior: "smooth"
              });

            });
        }}
      >Зробити замовлення<br></br>з оплатою</button>

      {error && !redirect ?
        <div style={{ color: 'red', textAlign: 'center' }}>Щось пішло не по плану. Перевірте з'єднання або спробуйте зробити замовлення без оплати</div>
        :
        null}


      {/* ниже будет появляться форма для уточнения */}
      {redirect && <FormClarifyPay props={{ data, signature, goRedirect, fetchingToTelegram, wishCall, setError, dispatch, fetchToUpdateUsedPromo, resetAllCart, error }} />
      }
    </>
  )
}



export default ButtonPay;

import PizzaList from "../pizza-list/PizzaList";
import MoreFilters from "./more-filters/MoreFilters";

import './pizzablock.scss';
//test
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';


//данные для формирования сортировки
const liSort = [{ cat: "rating", title: 'за рейтингом', order: 'desc' }, { cat: "price", title: 'за зменшенням ціни', order: 'desc' }, { cat: "price", title: 'за збільшенням ціни', order: 'asc' }];
//данные для формиования списка
const liFilters = [{ id: '', title: 'Всі' }, { id: 'new', title: 'Новинки' }, { id: 'vegan', title: 'Веганські' }, { id: 'hit', title: 'Топ' }];

const Pizzablock = () => {
  //я делаю тnavigate(/) в фильтрационном меню, что у нас правильно работала прокрутка к дринксам. Есл єто убрать, то в случае хеша дринкс, при ререндере (после получения пиццалоадинга = ок будет прокручиваться до напитков)...
  const navigate = useNavigate();
  const location = useLocation();




  //будем делать фильтрацию, локальный стэйт с определенным наименованием условной категории и передавать его в пицца блок, где непосредственно происходит запрос для получения пицц. Это значение будем передавать в http запрос для последующей фильтрации на бєке.
  const [filter, setFilter] = useState('');

  const [filtersMore, setFiltersMore] = useState({});

  //ниже стєйт для сортировки. Тоже будем передавать ниже для хттп запроса...
  const [sorting, setSorting] = useState({ cat: "rating", title: 'за рейтингом', order: 'desc' });

  //ниже стэйт для появления блока с фильтрами
  const [isMoreFilters, setIsMoreFilters] = useState(false);
  //ниже стєйт для появления блока сортировки
  const [isSorting, setIsSorting] = useState(false);


  //ниже уже знакомый нам паттерн с рефами и слушателем событий (закрытие окошка сортировки)
  const ref = useRef();
  const triggerRef = useRef();
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && triggerRef.current && !triggerRef.current.contains(event.target)) {
      setIsSorting(!isSorting);
    }
  }
  useEffect(() => {
    if (location.pathname === '/' && location.hash !== '#drinks') {
      window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (isSorting) {
      document.addEventListener('click', handleClickOutside, { passive: true });
      return () => {
        document.removeEventListener('click', handleClickOutside, { passive: true });
      }
    }
  }, [isSorting]);
  //паттерн окончен



  const sortItems = liSort.map((item, index) => {
    return (
      <li
        onClick={() => {
          setSorting(() => ({ ...sorting, cat: item.cat, title: item.title, order: item.order }));
          setIsSorting(!isSorting)
          navigate("/");
        }}
        key={item.title + index} className="sort-by-price__li">{item.title}</li>
    )
  });

  //создаем массив фильтров с джсИкс разметкой, тут же проверка на активный класс (привязка АЙДИ фильтра к ЛОКАЛЬНОМУ СТЭЙТУ - filter)
  const liItems = liFilters.map((item, ind) => {
    return (
      <li
        onClick={() => {
          setFilter(item.id);
          navigate("/");
        }}
        key={ind + item.title} className={filter === item.id ? "pizzablock__filter-item pizzablock__filter-active" : "pizzablock__filter-item"}>{item.title}</li>
    )
  });

  //считаем количество задействованных фильтров
  const filtersCount = (filter ? 1 : 0) + Object.keys(filtersMore).length;




  return (
    <>
      <div className="pizzablock" id="pizzablock">
        <div className="wrap-for-mobile">
          <h2 className="pizzablock__title">ПІЦА</h2>
          <div className="pizzablock__filter-sort">
            <ul className="pizzablock__filter-items">
              {liItems}
            </ul>
            {/* //ниже будет также условие, по которому будет появляться возможность удаления фильтров (только если их будет больше 0) */}

            {filtersCount ? <div
              onClick={() => {
                setFiltersMore({});
                setFilter('');
                setIsMoreFilters(false);
                navigate("/");
              }}
              className="pizzablock__reset-filters">скинути всі ({filtersCount}) фільтри ╳</div> : null}
            <div
              onClick={() => { setIsMoreFilters(!isMoreFilters) }}
              className={Object.keys(filtersMore).length > 0 ? 'pizzablock__morefilters pizzablock__morefilters-active' : 'pizzablock__morefilters'}>Більше фільтрів {isMoreFilters ? '▲' : '▼'}</div>
          </div>
          {isMoreFilters ? <MoreFilters filtersMore={filtersMore} setFiltersMore={setFiltersMore} /> : null}

          <div className="sort-by-price"
            ref={triggerRef}
            onClick={() => { setIsSorting(!isSorting) }}>{sorting.title} {isSorting ? '▲' : '▼'}</div>

          <div style={{ position: 'relative' }}>
            {isSorting ? <ul ref={ref} className="sort-by-price__ul">
              {sortItems} </ul> : null}
          </div>
        </div>
        {/*  передаем пропс фильтр и сортинг для последующего ХТТП запроса и фильтерсМор тоже для фильтрации, но уже не через запрос (потому что джсон сервер не справляется c двумя и более запросами)*/}
        <PizzaList filter={filter} sorting={sorting} filtersMore={filtersMore} />
      </div>



    </>
  )
}




export default Pizzablock;

import PizzaItem from "../pizza-item/PizzaItem";
import Constructor from "../pizza-item/constructor/Constructror";

//это скелетон, заглушка, которая будет показываться, когда асинхронный экшен (его промис) будет в состоянии ожидания (генералСлайс)
import Skeleton from "../skeleton/Skeleton";
import MSkeleton from "../skeleton/MSkeleton";

import useMedia768 from "../../hooks/useMedia";

//асинхронные экшены для получения из базы данных пицц и списка ингредиентов
import { fetchPizza } from "../../features/generalSlice";

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux/es/exports";

//из пропсов получаем информацию для фильтрации и сортировки из родительского ПИЦЦАБЛОК
const PizzaList = ({ filter, filtersMore, sorting }) => {

  const isMobile = useMedia768();

  const dispatch = useDispatch();
  //мне удобно, что сюда приходит информация с фильтрацией и сортировкой для запросов и удобно отсюда делать запросі, таким образом обновляя не всю аппку, а только пиццалист, поэтому сделаю переменную, которая будет сообщать - первый ли это маунтинг пиццаблока. Если первый, то запрос уходить не будет (он уже уходил из app,js со стандартными параметрами), если не первый, то пожалуйса
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      dispatch(fetchPizza({ sorting: { cat: "rating", title: 'за рейтингом', order: 'desc' }, filter: null }));
    }
  }, [])
  //в ФЕТЧПИЦЦА, а это createAsyncThunk, мы можем передать только один аргумент. И если нужно передать несколько значений, значит будем передавать объект.
  //создали отдельный ЮЗЭФФЕКТ, потому что запрос должен меняться в зависимости от выбранного пользователем фильтра.
  //когда компонент размаунтится, на случай, если кто-то применил фильтры - делаем стандртный запрос за пиццами, как в app.js
  useEffect(() => {
    if (isMounted) {
      dispatch(fetchPizza({ filter, sorting }));
    }

  }, [filter, sorting]);


  //эта переменная из генерального среза будет помогать нам понять, что показывать и как прошел запрос. Толи запрос прошел успешно и выводить списки, толи идет ожидание - скелетон, толи ошибка - ошибка.
  const pizzaLoadingStatus = useSelector(
    (state) => state.general.pizzaLoadingStatus
  );

  //ниже сам массив с объектами (пиццами)
  const data = useSelector((state) => state.general.pizzas);


  //ниже моя уродливая проверка на то, получены ли элементы
  const loadingBorts = useSelector(state => state.general.loadingBorts);
  const loadingCatOfIngred = useSelector(state => state.general.loadingCatOfIngred);
  const loadingIngr = useSelector(state => state.general.loadingIngr);


  //если загрузка, то скелетое
  if (pizzaLoadingStatus !== "ok" || loadingBorts !== 'ok' || loadingIngr !== 'ok' || loadingCatOfIngred !== 'ok') {
    return (
      <div className="pizzablock__pizza">
        <ul className="pizzablock__pizza-items">
          {isMobile ? (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((item, index) => <MSkeleton key={item} />)}
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((item, index) => <Skeleton key={item} />)}
            </>
          )}
        </ul>
      </div>
    );
  }
  //если ошибка - ошибка
  /* else if (pizzaLoadingStatus === "error") {
    return "Сталася якась помилка, спробуйте оновити сторінку...";
  } */
  //два return выше закончат выполнение функции, если нет, то формируем пицца-айтемы, передавая в соответствующие компоненты пропсы в виде объекта с данными конкретной пиццы
  //но тут же мы будем пытаться фильровать результат, если в объекте с допонительными фильтрами что-то есть...
  const items =
    !Object.keys(filtersMore).length > 0
      ? data.map((item) => {
        return <PizzaItem key={item.id} info={item} />;
      })
      : //ниже использовали новый интересный метод every(), который возвращает тру, если каждый элемент массива удовлетворяет условию, переданному в кол бэк функцию.
      data
        .filter((item) => {
          return Object.values(filtersMore).every((elem) => {
            return item.descr.includes(elem.toString());
          });
        })
        .map((item) => {
          return <PizzaItem key={item.id} info={item} />;
        });

  return (
    <div id="pizzalist" className="pizzablock__pizza">
      <ul className="pizzablock__pizza-items">
        <Constructor />
        {items.length === 0
          ? "Таких піц, які ви шукаєте, не знайдено..."
          : items}
      </ul>
    </div>
  );
};

export default PizzaList;

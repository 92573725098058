import "./addIngredients.scss";
import "./m-addIngredients.scss";

import plus from "../../../assets/plus.png";

import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useMedia768 from "../../../hooks/useMedia";

const AddIngredients = ({ extraIngr, isUsualPizza, pathToRedirect, setExtraIngr, polo, setExtraMore, setExtraMore2, priceForOne, authorsPizza }) => {
  const navigate = useNavigate();
  //ниже формируем локальный РЕАКТ СТЭЙТ for ModPizza для хранения добавленых ингредиентов. Формируем с помощью функции.
  const formStateWithExtraIngr = (idOfIngr, price) => {
    setExtraIngr(() => {
      let copyObj = { ...extraIngr };
      if (!copyObj[idOfIngr]) {
        copyObj[idOfIngr] = [];
      }
      copyObj[idOfIngr].push({ idOfIngr, price: price });
      return copyObj;
    });
  };
  //ниже функция для минусования количества дополнительных ингредиентов
  const minusExtraIngr = (id) => {
    let copyObj = { ...extraIngr };
    if (extraIngr[id] && extraIngr[id].length === 1) {
      setExtraIngr(() => {
        delete copyObj[id];
        return copyObj;
      });
    } else {
      setExtraIngr(() => {
        copyObj[id].pop();
        return copyObj;
      });
    }
  };

  const isMobile = useMedia768();

  //создам функцию, которая будет просчитывать количество добавленных ингредиентов (для категорий)
  const countForCat = (cat) => {
    const reg = new RegExp(`^${cat}`);
    const initial = 0;
    return Object.keys(extraIngr)
      .filter((item) => item.match(reg))
      .reduce((acc, cur) => {
        return acc + extraIngr[cur].length;
      }, initial);
  };

  //in View мы передаем активный класс категории под АЙДИ 2, если посмотреть на базу данных, то все МЯСные продукты начинаются на двойку, именно поэтому ниже ты найдешь регулярное выражение.
  const [activeClass, setActiveClass] = useState(2);
  // addIngredients__borderB
  //ниже сделаем разніе фоны для разных категорий
  //создадим переменную, чтобы менять в зависимости от ширина экрана ситли
  const classChosen = isMobile
    ? "m-addIngredients__ingr"
    : "addIngredients__ingr";
  let ingredientsColorStyle = "";
  let catColorStyle = "";
  let messageAboutCat = "";


  switch (activeClass.toString()) {
    case "2":
      ingredientsColorStyle = classChosen + " lred";
      catColorStyle = " lred";
      messageAboutCat = `*стандартна піца складається з щонайменше 4
      порцій м'ясних виробів (філе лосося - однієї порції)`;
      break;
    case "4":
      ingredientsColorStyle = classChosen + " lgreen";
      catColorStyle = " lgreen";
      messageAboutCat = `*здебільшого стандартна піца складається з щонайменше 1 порції овочів одного виду`;
      break;
    case "8":
      ingredientsColorStyle = classChosen + " lyel";
      catColorStyle = " lyel";
      messageAboutCat = `*здебільшого стандартна піца складається з щонайменше 2 порцій сирів (Моцарела,\u00A0Гауда)`;
      break;
    case "7":
      ingredientsColorStyle = classChosen + " lred";
      catColorStyle = " lred";
      messageAboutCat = `*піца обов'язково повинна містити червоний або білий соус. Наявність червоного соусу виключає наявність білого та навпаки. Інші соуси - додаткові`;
      break;
    default:
      ingredientsColorStyle = classChosen;
  }

  const allIngredients = useSelector((state) => state.general.ingr);
  const regExp = new RegExp(`^${activeClass}`, "g");
  const filteredIngred = Object.keys(allIngredients)
    .filter((item) => {
      return item.match(regExp);
    })
    .map((item, index) => {

      return (
        <>
          {isMobile ? (
            <li className={ingredientsColorStyle} key={item + 1 + index}>
              <div className="m-addIngredients__wr-info">
                <span className="addIngredients__ingr-title">
                  {allIngredients[item][0]}
                </span>

                <div className="m-addIngredients__ingr-price-wrapper">
                  <span>(порція&nbsp;</span>
                  <span className="addIngredients__ingr-price">
                    {allIngredients[item][1]} грн)
                  </span>
                </div>
              </div>

              {!extraIngr[item] ? (
                <img
                  src={plus}
                  alt="plus"
                  onClick={() => {
                    formStateWithExtraIngr(item, allIngredients[item][1]);
                  }}
                  className="m-addIngredients__ingr-count"
                ></img>
              ) : (
                <div className="m-addIngredients__config-count">
                  <button
                    onClick={() => {
                      minusExtraIngr(item);
                    }}
                    className="m-addIngredients__minus"
                  >
                    -
                  </button>
                  <span className="aside-cart__count">
                    {extraIngr[item].length}
                  </span>
                  <button
                    onClick={() => {
                      formStateWithExtraIngr(item, allIngredients[item][1]);
                    }}
                    className="m-addIngredients__plus"
                  >
                    +
                  </button>
                </div>
              )}
            </li>
          ) : (
            <li className={ingredientsColorStyle} key={item}>
              <span className="addIngredients__ingr-title">
                {allIngredients[item][0]}
              </span>
              <div className="addIngredients__ingr-some-wrapper">
                <div className="addIngredients__ingr-price-wrapper">
                  <span>порція&nbsp;</span>
                  <span className="addIngredients__ingr-price">
                    {allIngredients[item][1]} грн
                  </span>
                </div>
                {!extraIngr[item] ? (
                  <img
                    src={plus}
                    alt="plus"
                    onClick={() => {
                      formStateWithExtraIngr(item, allIngredients[item][1]);
                    }}
                    className="addIngredients__ingr-count"
                  ></img>
                ) : (
                  <div className="aside-cart__footer-config-count">
                    <button
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "2px",
                      }}
                      onClick={() => {
                        minusExtraIngr(item);
                      }}
                      className="aside-cart__min"
                    >
                      -
                    </button>
                    <span className="aside-cart__count">
                      {extraIngr[item].length}
                    </span>
                    <button
                      style={{
                        width: "25px",
                        height: "25px",
                        marginLeft: "2px",
                      }}
                      onClick={() => {
                        formStateWithExtraIngr(item, allIngredients[item][1]);
                      }}
                      className="aside-cart__plus"
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
            </li>
          )}
        </>
      );
    });

  const catOfIngred = useSelector((state) => state.general.catOfIngred);
  //нужна проверка
  const categories = catOfIngred.map((item, index) => {
    const classNames =
      activeClass == item.id
        ? "addIngredients__ingr-cat addIngredients__borderB" + catColorStyle
        : "addIngredients__ingr-cat";
    const count = countForCat(item.id) ? " (" + countForCat(item.id) + ")" : null;
    if (item.id == 1 || item.id == 3) {
      return null;
    }
    return (
      <li
        onClick={() => {
          setActiveClass(item.id);
        }}
        id={item.id}
        key={item.id + 1 + index + 1}
        className={classNames}
      >
        {item.name}
        {count}
      </li>
    );
  });

  return (
    <>
      {isMobile ? (
        <>
          {
            <div className="m-addIngredients__wr">
              <div>
                <ul className="addIngredients__cat-list m-addIngredients__cat-list">
                  {categories}
                </ul>
                <div>
                  <ul className="m-addIngredients__list">{filteredIngred}</ul>
                </div>

                <div style={{ fontSize: 12, marginTop: 5 }}>
                  {messageAboutCat}
                </div>
              </div>
              <button
                className="m-addIngredients__reset-mod"
                onClick={() => {
                  setExtraIngr({});
                  setExtraMore({});
                  setExtraMore2({});
                }}
              >
                Очистити весь вибір
              </button>
              <div className="m-addIngredients__priceForOne">{priceForOne} грн за од.</div>
              <button
                className="m-addIngredients__submit"
                onClick={() => {
                  if (polo) {
                    navigate('/halfs');
                  }
                  else if (authorsPizza) {
                    navigate('/constructor');
                  }
                  else if (isUsualPizza) {
                    navigate(pathToRedirect)
                  }
                }}
              >
                Завершити додавання інгредієнтів
              </button>
            </div>
          }
        </>
      ) : (
        <>
          <ul className="addIngredients__cat-list">{categories}</ul>
          <div>
            <ul className="addIngredients__list">{filteredIngred}</ul>
          </div>
          {activeClass == 2 ? (
            <div style={{ fontSize: 12 }}>
              *здебільшого стандартна піца скадається з щонайменше 4 порцій
              м'ясних виробів (філе лосося - однієї порції)
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default AddIngredients;

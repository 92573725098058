

const AsideCartItemDrinks = ({ img, name, id, dispatch, deletePositionCart, minusToCart, plusToCart, cartData, thisPizzaTotalPrice, volume }) => {


  return (
    <div className="aside-cart__item">
      <div className="aside-cart__body">
        <div className="flex">
          <img src={img} alt="" className="aside-cart__body-pizza-img aside-cart__body-pizza-img_drinks" />
          <div className="aside-cart__pizparam-wrap">
            <div className="aside-cart__body-pizza-title">
              {name}
            </div>
            <div className="aside-cart__body-params">{volume} л</div>
          </div>
        </div>
        <button
          onClick={() => {
            dispatch(deletePositionCart(id));
          }}
          className="aside-cart__body-delete"
        >
          ✖
        </button>
      </div>
      <div className="aside-cart__footer">
        <div className="aside-cart__footer-config-count">
          <button
            onClick={() => {
              dispatch(minusToCart(id));
            }}
            className="aside-cart__min btn-minus"
          >
            -
          </button>
          <span className="aside-cart__count">
            {cartData[id] ? cartData[id].length : 0}
          </span>
          <button
            onClick={() => {
              dispatch(plusToCart(id));
            }}
            className="aside-cart__plus btn-plus"
          >
            +
          </button>
        </div>
        <div className="aside-cart__price">{thisPizzaTotalPrice} грн</div>
      </div>
    </div>
  );
}

export default AsideCartItemDrinks;

import "./about.scss";

import otdel from "../assets/otdel.jpg";
import ImgWithPreloadSpinner from "../components/little/ImgWithPreloadSpinner";

import { useEffect } from "react";

const About = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="about">
      <div className="container">
        <h1 className="about__title">Про нас</h1>
        <div className="about__text">
          <p>
            <strong>M2T Pizza</strong> — це сучасна експрес-піцерія, яка орієнтована на
            постійну якість продукції, швидке обслуговування та прозорість всіх процесів приготування.
          </p>
          <ImgWithPreloadSpinner src={otdel} alt="photo of pizzeria" className="about__present-img" />
          <p>
            Завдяки відкритій кухні, кожен може на власні очі побачити - з яких
            продуктів та в яких умовах народжується смачна італійська страва у
            виконанні досвідчених кухарів-піцамейкерів.
          </p>
          <br />
          <p>
            Заклад працює без посадкових місць в форматі "на виніс" та має власну службу доставки, зона обслуговування якої покриває місто Добропілля, а також селище Ганнівка.
          </p>
          <br />
          <p>
            Наразі діє тимчасовий графік роботи, відповідно до якого піцерія працює
            щодня з 10:00 до 18:00.
          </p>
        </div>
        <br />
        <div className="about__cnt-info">
          <address>
            <p>
              Контактна інформація:
            </p>
            <br />
            м. Добропілля, м-н Сонячний 30а (ТЦ&nbsp;«Добропілля»)
            <br />
            <br />
            Тел. для замовлень:<br />
            <a href="tel:+380993965777">
              099 3965 777
            </a>
            <br />
            <br />
            Ел. адреса адміністрації: <br /> <a href="mailto:administration@m2t.pizza">administration@m2t.pizza</a>
            <br />
            <br />
            Написати пропозицію або зауваження можна також через Telegram-бота:<br /><a target='_blank
' href="https://t.me/m2t_fbot">t.me/m2t_fbot</a>
            <br />
            <br />
            Інстаграм:<a className='promotion__smlink' target='_blank' href='https://www.instagram.com/m2t.pizza/'>@m2t.pizza</a>
          </address>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.405574195947!2d37.083391476875015!3d48.467933728104136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40de5382ba4d7c2b%3A0xe83b8254714c40f2!2sM2T%20Pizza!5e0!3m2!1sru!2sus!4v1704222202592!5m2!1sru!2sus" className="about__map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>
    </section >
  );
};

export default About;

import './footer.scss'
import { NavLink } from 'react-router-dom';

import bankingSptite from '../../assets/banking/ico-sprite.svg';
import instagramSvgIco from '../../assets/insta.svg';

const Footer = () => {

  const activeStyle = { color: "#8e2a2a" };

  return (
    <footer className="footer">
      <div className="container footer__main-block">
        {/* <span>© M2T ENTERPRISE | 2023</span> */}
        <div className="footer__first">
          <ul className="footer__menu">

            <li><NavLink
              to="/"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Головна</NavLink></li>
            <li><NavLink
              to="/about"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Про нас</NavLink></li>
            <li><NavLink
              to="/promotion"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Акції</NavLink></li>
            <li><NavLink
              to="/legend"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Легенда</NavLink></li>


          </ul>
        </div>
        <div className="footer__second">
          <ul className='footer__law'>
            <li><NavLink
              to="/public-offer"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Публічна оферта</NavLink></li>
            <li><NavLink
              to="/shipping-payment"
              style={({ isActive }) =>
                isActive ? activeStyle : undefined
              }>Доставка й оплата</NavLink></li>

          </ul>
        </div>
        <div className="footer__third">
          <svg className='bank-svg'>
            <use href={`${bankingSptite}#visa`}></use>
          </svg>
          <svg className='bank-svg'>
            <use href={`${bankingSptite}#mastercard`}></use>
          </svg>
        </div>
        <a href='https://www.instagram.com/m2t.pizza/' target='_blanc' className="footer__four">
          Слідкуйте за нами: <img src={instagramSvgIco} alt="instagram icon" /></a>

      </div>

    </footer>
  )
}

export default Footer;

import "./halfs.scss";

import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useNavigate, Outlet } from "react-router-dom";


import useMedia768 from "../../hooks/useMedia";

import PCHalfs from "./pc/PCHalfs";
import useExtrasPriceSum from "../../hooks/useExtrasPriceSum";
import useComponentVisible from "../../hooks/useComponentVisible";

//ниже ф-ция для подсчета количества всех добавок в отдельной половинке или всей пицце
const countOfExtras = (arrWithExtras) => {
  return Object.values(arrWithExtras).reduce((accum, current) => {
    return accum + current.length;
  }, 0);
};

const Halfs = () => {
  const isMobile = useMedia768();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const ref = useRef(null);

  const navigate = useNavigate();
  //я зафетчил полоПиццы в компоненте Апп. И никаких проверок не делал, к слову.
  const [firstId, setFirstId] = useState(1);
  const [secondId, setSecondId] = useState(6);

  const [showFirstPolo, setShowFirstPolo] = useState(false);
  const [showSecondPolo, setShowSecondPolo] = useState(false);

  //ниже используем хук, который закрывает. Экономим место.
  const refForClickOutside1 = useComponentVisible(
    showFirstPolo,
    setShowFirstPolo
  );
  const refForClickOutside2 = useComponentVisible(
    showSecondPolo,
    setShowSecondPolo
  );
  const loadingPizzaPolos = useSelector(
    (state) => state.general.loadingPizzaPolos
  );
  const pizzasPolo = useSelector((state) => state.general.poloPizzas);
  const ingr = useSelector((state) => state.general.ingr);

  const [bortIndex, setBortIndex] = useState("0");
  const borts = useSelector((state) => state.general.borts);
  const loadingBorts = useSelector((state) => state.general.loadingBorts);
  const loadingIngr = useSelector((state) => state.general.loadingIngr);

  const [deletedIngrForOne, setDeletedIngrForOne] = useState({});
  const [deletedIngrForTwo, setDeletedIngrForTwo] = useState({});
  const [countModPizza, setCountModPizza] = useState(1);

  const [addToAll, setAddToAll] = useState(true);
  const [extraIngr, setExtraIngr] = useState({});
  const [addToFirst, setAddToFirst] = useState(false);
  const [extraIngrFirst, setExtraIngrFirst] = useState({});
  const [addToSecond, setAddToSecond] = useState(false);
  const [extraIngrSecond, setExtraIngrSecond] = useState({});

  const extrasPriceSum = useExtrasPriceSum(extraIngr);
  const extrasPriceSumForFirst = useExtrasPriceSum(extraIngrFirst);
  const extrasPriceSumForSecond = useExtrasPriceSum(extraIngrSecond);

  //ниже проверка, требует доработки
  if (
    loadingPizzaPolos !== "ok" ||
    loadingBorts !== "ok" ||
    loadingIngr !== "ok"
  ) {
    return null;
  }
  //ниже находим в массиве нужные нам объекты
  const [firstPolo] = pizzasPolo.filter((item) => {
    return +item.id === +firstId;
  });
  const [secondPolo] = pizzasPolo.filter((item) => {
    return +item.id === +secondId;
  });

  //чего-то в пропсы не могу пропихнуть имя и изображение, поэтому выведу в отедельные переменные
  const namePoloOne = firstPolo.name;
  const namePoloTwo = secondPolo.name;
  const firstPoloImg = firstPolo.img;
  const secondPoloImg = secondPolo.img;

  //ниже считаем цену финальную
  const modPrice =
    (firstPolo.price +
      secondPolo.price +
      10 +
      extrasPriceSum +
      extrasPriceSumForFirst +
      extrasPriceSumForSecond +
      borts[bortIndex].price) *
    countModPizza;
  const priceForOne = (firstPolo.price +
    secondPolo.price +
    10 +
    extrasPriceSum +
    extrasPriceSumForFirst +
    extrasPriceSumForSecond +
    borts[bortIndex].price);
  //вконце фулайди добавил "f", чтобы это была нечисловая строка, чтобы объект сортировал данные в пооядке добавления
  const fullId =
    firstPolo.id +
    secondPolo.id +
    bortIndex +
    Object.keys(deletedIngrForOne)
      .sort((a, b) => a - b)
      .join("") +
    1 +
    Object.keys(deletedIngrForTwo)
      .sort((a, b) => a - b)
      .join("") +
    2 +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .join("") +
    3 +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .map((item) => extraIngr[item].length)
      .join("") +
    4 +
    Object.keys(extraIngrFirst)
      .sort((a, b) => a - b)
      .join("") +
    5 +
    Object.keys(extraIngrFirst)
      .sort((a, b) => a - b)
      .map((item) => extraIngrFirst[item].length)
      .join("") +
    6 +
    Object.keys(extraIngrSecond)
      .sort((a, b) => a - b)
      .join("") +
    7 +
    Object.keys(extraIngrSecond)
      .sort((a, b) => a - b)
      .map((item) => extraIngrSecond[item].length)
      .join("") +
    "f";

  //ниже получаем списки
  const firstPizzasPolo = pizzasPolo
    .filter((item) => item.id !== firstPolo.id)
    .map((item) => {
      return (
        <li
          key={item.id}
          data-pid={item.id}
          onClick={(e) => {
            setFirstId(e.target.getAttribute("data-pid"));
            setDeletedIngrForOne({});
            setExtraIngrFirst({});
          }}
          className="halfs__polo-li"
        >
          {item.name} ({item.price} грн)
        </li>
      );
    });
  const secondPizzasPolo = pizzasPolo
    .filter((item) => item.id !== secondPolo.id)
    .map((item) => {
      return (
        <li
          key={item.id}
          data-pid={item.id}
          onClick={(e) => {
            setSecondId(e.target.getAttribute("data-pid"));
            setDeletedIngrForTwo({});
            setExtraIngrSecond({});
          }}
          className="halfs__polo-li"
        >
          {item.name} ({item.price} грн)
        </li>
      );
    });

  if (isMobile) {
    return (
      <Outlet
        context={{
          priceForOne,
          ref,
          navigate,
          refForClickOutside1,
          showFirstPolo,
          setShowFirstPolo,
          firstPolo,
          firstPizzasPolo,
          refForClickOutside2,
          showSecondPolo,
          setShowSecondPolo,
          secondPolo,
          secondPizzasPolo,
          borts,
          setBortIndex,
          bortIndex,
          ingr,
          deletedIngrForOne,
          deletedIngrForTwo,
          setDeletedIngrForOne,
          setDeletedIngrForTwo,
          setAddToAll,
          setAddToFirst,
          setAddToSecond,
          addToAll,
          countOfExtras,
          extraIngr,
          setExtraIngr,
          addToFirst,
          namePoloOne,
          namePoloTwo,
          extraIngrFirst,
          extraIngrSecond,
          setExtraIngrSecond,
          setExtraIngrFirst,
          addToSecond,
          modPrice,
          countModPizza,
          setCountModPizza,
          fullId,
          firstPoloImg,
          secondPoloImg,
        }}
      />
    );
  } else {
    return (
      <PCHalfs
        propses={{
          ref,
          navigate,
          refForClickOutside1,
          showFirstPolo,
          setShowFirstPolo,
          firstPolo,
          firstPizzasPolo,
          refForClickOutside2,
          showSecondPolo,
          setShowSecondPolo,
          secondPolo,
          secondPizzasPolo,
          borts,
          setBortIndex,
          bortIndex,
          ingr,
          deletedIngrForOne,
          deletedIngrForTwo,
          setDeletedIngrForOne,
          setDeletedIngrForTwo,
          setAddToAll,
          setAddToFirst,
          setAddToSecond,
          addToAll,
          countOfExtras,
          extraIngr,
          setExtraIngr,
          addToFirst,
          namePoloOne,
          namePoloTwo,
          extraIngrFirst,
          extraIngrSecond,
          setExtraIngrSecond,
          setExtraIngrFirst,
          addToSecond,
          modPrice,
          countModPizza,
          setCountModPizza,
          fullId,
          firstPoloImg,
          secondPoloImg,
        }}
      />
    );
  }
};

export default Halfs;

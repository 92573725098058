import { useNavigate } from "react-router-dom";

import AddIngredients from "../add-ingredients/AddIngredients";
import DescrElemWithDeleteFunc from "../delete-ingredients/DescrElemWithDeleteFunc";
import ListOfAddIngrWithDeleteFunc from "../add-ingredients/ListOfAddIngrWithDeleteFunc";
import ChosenBorts from "../../little/ChosenBorts";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";

export default function PCModPizza({ props }) {
  const navigate = useNavigate();
  const { deletedIngr, setDeletedIngr, descr, ingr, name, weight, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, modPrice, countModPizza, setCountModPizza, fullId, img } = props;
  return (
    <div
      className="modal"
      //при клике на подложку тоглится модальное окно
      onClick={() => {
        navigate("/");
      }}
    >
      <div
        className="modal__pizza"
        //c основного контента мы убираем всплытие и, таким образом, при нажатии на него тогла не будет.
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          onClick={() => {
            navigate("/");
          }}
          className="m-modal__close"
        >
          ╳
        </div>
        <div className="modal__pizza-wrapper">
          <div className="modal__pizza-col-one">
            <img className="modal__pizza-img" src={img} alt="" />
            <div>
              <strong>ВИДАЛИТИ ІНГРЕДІЄНТИ ↓</strong>
            </div>
            <ul className="modal__pizza-descr">
              <DescrElemWithDeleteFunc
                deletedIngr={deletedIngr}
                setDeletedIngr={setDeletedIngr}
                descr={descr}
                ingr={ingr}
              />
            </ul>
          </div>

          <div className="modal__pizza-col-sec">
            <div className="modal__pizza-info">
              <h2 className="modal__pizzaName">{name}</h2>
              <span className="modal__pizza-weight">{weight} | 30 см</span>
            </div>
            <ChosenBorts
              borts={borts}
              bortIndex={bortIndex}
              setBortIndex={setBortIndex}
            />
            <div className="halfs__apart-block-toadd">
              <div className="modal__pizza-additionally-ingr">
                Додати інгредієнти ↓
              </div>
              <AddIngredients
                extraIngr={extraIngr}
                setExtraIngr={setExtraIngr}
              />
            </div>
            {Object.keys(extraIngr).length ? (
              <>
                <div style={{ marginTop: 10 }}>
                  <strong>Додано наступні інгредієнти:</strong>
                </div>
                <ul className="modal__pizza-extra">
                  <ListOfAddIngrWithDeleteFunc
                    ingr={ingr}
                    extraIngr={extraIngr}
                    setExtraIngr={setExtraIngr}
                  />
                </ul>
              </>
            ) : null}
            <BlockWithPriceCountCart
              modPrice={modPrice}
              countModPizza={countModPizza}
              setCountModPizza={setCountModPizza}
              fullId={fullId}
              borts={borts}
              bortIndex={bortIndex}
              deletedIngr={deletedIngr}
              name={name}
              img={img}
              extraIngr={extraIngr}
            />
          </div>
        </div>
        {/* ниже создам крестик для закрытия    */}
        {/* <span
              className="modal__pizza-close"
              onClick={() => {
                navigate(-1);
              }}
            >
              ╳
            </span> */}
      </div>
    </div>
  )
}

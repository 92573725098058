import { useDispatch, useSelector } from "react-redux";
import {
  deletePositionCart,
  plusToCart,
  minusToCart,
} from "../../../features/cartSlice";

import AsideCartItemDrinks from "./asideCartItemVars/AsideCartItemDrinks";
import AsideCartItemPolo from "./asideCartItemVars/AsideCartItemPolo";

const AsideCartItem = ({
  bortName,
  price,
  img,
  name,
  id,
  deleted,
  extra,
  polo,
  kind,
  volume,
  availability
}) => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.cart);
  const ingr = useSelector((state) => state.general.ingr);

  //ниже считаем общую сумму по позиции (пицца одного вида с определенным бортиком и определенными добавками в определенном количестве)
  const thisPizzaTotalPrice = price * cartData[id].length;
  if (kind === 'drinks') {
    return <AsideCartItemDrinks img={img} name={name} id={id} dispatch={dispatch} deletePositionCart={deletePositionCart} minusToCart={minusToCart} plusToCart={plusToCart} cartData={cartData} thisPizzaTotalPrice={thisPizzaTotalPrice} volume={volume} availability={availability} />

  }

  else if (polo) {
    return <AsideCartItemPolo deleted={deleted} ingr={ingr} name={name} extra={extra} img={img} price={price} cartData={cartData} id={id} bortName={bortName} deletePositionCart={deletePositionCart} minusToCart={minusToCart} plusToCart={plusToCart} dispatch={dispatch} />
  }
  //ниже пицца обычная
  else {
    const deletedArr = Object.keys(deleted);
    //ingr[item][0] ниже - из-за структуры моей базы данных
    const deletedIngr =
      deletedArr.map((item) => {
        return <li key={item}>-{ingr[item][0]}</li>;
      }) || null;

    const extraIngr =
      Object.keys(extra).map((item, index) => {
        return (
          <li key={item + index + 1}>
            +{ingr[item][0]} ({extra[item].length})
          </li>
        );
      }) || null;

    return (
      <div className="aside-cart__item">
        <div className="aside-cart__body">
          <div className="flex">
            <img src={img} alt="" className="aside-cart__body-pizza-img" />
            <div className="aside-cart__pizparam-wrap">
              <div className="aside-cart__body-pizza-title">
                {name}
                {/*  {deletedArr.length !== 0 || Object.keys(extra).length !== 0 ? (
                  <span>(модифікована)</span>
                ) : null} */}
              </div>
              <div className="aside-cart__body-params">{bortName} борт</div>
              <ul className="aside-cart__body-extra">{extraIngr}</ul>
              <ul className="aside-cart__body-deleteIngr">{deletedIngr}</ul>
            </div>
          </div>
          <button
            onClick={() => {
              dispatch(deletePositionCart(id));
            }}
            className="aside-cart__body-delete"
          >
            ✖
          </button>
        </div>
        <div className="aside-cart__footer">
          <div className="aside-cart__footer-config-count">
            <button
              onClick={() => {
                dispatch(minusToCart(id));
              }}
              className="aside-cart__min btn-minus"
            >
              -
            </button>
            <span className="aside-cart__count">
              {cartData[id] ? cartData[id].length : 0}
            </span>
            <button
              onClick={() => {
                dispatch(plusToCart(id));
              }}
              className="aside-cart__plus btn-plus"
            >
              +
            </button>
          </div>
          <div className="aside-cart__price">{thisPizzaTotalPrice} грн</div>
        </div>
      </div>
    );
  }
};

export default AsideCartItem;

import ChosenBorts from "../../little/ChosenBorts";
import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import ListOfAddIngrWithDeleteFunc from "../../mod-pizza/add-ingredients/ListOfAddIngrWithDeleteFunc";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";

export default function PCAuthorsPizza({ navigate, img, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, ingr, name, modPrice, countModPizza, setCountModPizza, fullId }) {


  return (<div
    className="modal"
    //при клике на подложку тоглится модальное окно
    onClick={() => {
      navigate("/");
    }}
  >
    <div
      className="modal__pizza pcauthors-pizza"
      //c основного контента мы убираем всплытие и, таким образом, при нажатии на него тогла не будет.
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        onClick={() => {
          navigate("/");
        }}
        className="m-modal__close"
      >
        ╳
      </div>
      <div className="modal__pizza-wrapper">
        <div className="modal__pizza-col-one modal__pizza-col-one--pcauthors">
          <div className="modal__pizza-info modal__pizza-info--pcauthors">
            <h2 className="modal__pizzaName">{name}</h2>
            <span className="modal__pizza-weight">30 см</span>
          </div>
          <img className="modal__pizza-img modal__pizza-img--pcauthors" src={img} alt="" />
          <div
            className="pcauthors-pizza__advice">
            <p className="pcauthors-pizza__advice-p">Початковий набір інгредієнтів складає дві порції сирів (Моцарела,&nbsp;Гауда), а також червоний соус.</p><p className="pcauthors-pizza__advice-p">Ви можете змінити ці інгредієнти, але, так чи інакше, радимо, щоб піца обов'язково містила білий або червоний соус, які перші наливаються на тісто та роблять страву соковитою.</p>
            <p className="pcauthors-pizza__advice-p">Білий та червоний соуси, за загальним правилом, є взаємовиключними. Решта ж соусів - є додатковими.</p>

          </div>

        </div>

        <div className="modal__pizza-col-sec modal__pizza-col-sec--pcauthors">

          <div className="halfs__apart-block-toadd">
            <div className="modal__pizza-additionally-ingr">
              Керування інгредієнтами ↓
            </div>
            <AddIngredients
              extraIngr={extraIngr}
              setExtraIngr={setExtraIngr}
            />
          </div>

          <div>
            <div
              className="pcauthors-pizza__auth-choose"
            >
              <strong>Інгредієнти вашої піци:</strong>
            </div>
            <ul className="modal__pizza-extra">
              <ListOfAddIngrWithDeleteFunc
                ingr={ingr}
                extraIngr={extraIngr}
                setExtraIngr={setExtraIngr}
              />
            </ul>
            <ChosenBorts
              borts={borts}
              bortIndex={bortIndex}
              setBortIndex={setBortIndex}
            />

            <BlockWithPriceCountCart
              modPrice={modPrice}
              countModPizza={countModPizza}
              setCountModPizza={setCountModPizza}
              fullId={fullId}
              borts={borts}
              bortIndex={bortIndex}
              name={name}
              img={img}
              extraIngr={extraIngr}
            />
          </div>
        </div>
      </div>
    </div>


  </div>)
}

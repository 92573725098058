import ChosenBorts from "../../little/ChosenBorts";
import DescrElemWithDeleteFunc from "../../mod-pizza/delete-ingredients/DescrElemWithDeleteFunc";
import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import ListOfAddIngrWithDeleteFunc from "../../mod-pizza/add-ingredients/ListOfAddIngrWithDeleteFunc";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";
import ImgWithPreloadSpinner from "../../little/ImgWithPreloadSpinner";

import { useNavigate, useOutletContext, Link } from "react-router-dom";

import './mHalfs.scss';

const MHalfs = () => {
  const {
    refForClickOutside1,
    showFirstPolo,
    setShowFirstPolo,
    firstPolo,
    firstPizzasPolo,
    refForClickOutside2,
    showSecondPolo,
    setShowSecondPolo,
    secondPolo,
    secondPizzasPolo,
    borts,
    setBortIndex,
    bortIndex,
    ingr,
    deletedIngrForOne,
    deletedIngrForTwo,
    setDeletedIngrForOne,
    setDeletedIngrForTwo,
    setAddToAll,
    setAddToFirst,
    setAddToSecond,
    addToAll,
    countOfExtras,
    extraIngr,
    setExtraIngr,
    addToFirst,
    namePoloOne,
    namePoloTwo,
    extraIngrFirst,
    extraIngrSecond,
    setExtraIngrSecond,
    setExtraIngrFirst,
    addToSecond,
    modPrice,
    countModPizza,
    setCountModPizza,
    fullId,
    firstPoloImg,
    secondPoloImg,
  } = useOutletContext();

  const countOfDeletedAll = Object.keys(deletedIngrForOne).length + Object.keys(deletedIngrForTwo).length;
  const countOfAddedAll = [...Object.values(extraIngr).map(item => item.length), ...Object.values(extraIngrFirst).map(item => item.length), ...Object.values(extraIngrSecond).map(item => item.length)].reduce((a, b) => +a + +b, 0);

  const navigate = useNavigate();
  return (
    <div
      className="m-modal"
    >
      <div className="m-halfs">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="m-modal__close"
        >
          ╳
        </div>
        <div className="m-halfs__wrap-margin">
          <div className="halfs__wrap">
            <div style={{ position: "relative" }}>
              <span
                ref={refForClickOutside1}
                onClick={() => {
                  setShowFirstPolo(!showFirstPolo);
                }}
                className="halfs__polo-name"
              >
                {firstPolo.name} {showFirstPolo ? "▲" : "▼"}
              </span>

              {showFirstPolo ? (
                <ul className="halfs__polo-ul-first">{firstPizzasPolo}</ul>
              ) : null}
              <ImgWithPreloadSpinner
                src={firstPolo.img}
                alt="half-of-pizza"
                className="halfs__polo-img halfs__polo-img-left" />
            </div>

            <div style={{ position: "relative" }}>
              <span
                ref={refForClickOutside2}
                onClick={() => {
                  setShowSecondPolo(!showSecondPolo);
                }}
                className="halfs__polo-name"
              >
                {secondPolo.name} {showSecondPolo ? "▲" : "▼"}
              </span>

              {showSecondPolo ? (
                <ul className="halfs__polo-ul-second">{secondPizzasPolo}</ul>
              ) : null}
              <ImgWithPreloadSpinner
                src={secondPolo.img}
                alt="half-of-pizza"
                className="halfs__polo-img halfs__polo-img-right" />
            </div>
          </div>

          <ChosenBorts
            borts={borts}
            bortIndex={bortIndex}
            setBortIndex={setBortIndex}
          />
          <div className="m-halfs__add-del-wrap">
            <Link to={'./add-to-halfs'}>Додати інгредієнти
              {countOfAddedAll ? <span className="m-halfs__add-del-count">додано: ({countOfAddedAll})</span> : null}
            </Link>
            <Link to={'./delete-from-halfs'}>Видалити інгредієнти
              {countOfDeletedAll ? <span className="m-halfs__add-del-count">видалено: ({countOfDeletedAll})</span> : null}
            </Link>
          </div>

        </div>
        <div className="m-halfs__fixed-data">
          <div className="m-modal__action-wr">
            <BlockWithPriceCountCart
              polo={true}
              modPrice={modPrice}
              countModPizza={countModPizza}
              setCountModPizza={setCountModPizza}
              fullId={fullId}
              borts={borts}
              bortIndex={bortIndex}
              deletedIngr={{ deletedIngrForOne, deletedIngrForTwo }}
              name={{ namePoloOne, namePoloTwo }}
              img={{ firstPoloImg, secondPoloImg }}
              extraIngr={{ extraIngr, extraIngrFirst, extraIngrSecond }}
            />

          </div>
          <div className="m-halfs__clarify-cost">*вартість послуги поєднання - 10 грн</div>
        </div>




      </div>
    </div>
  );
};

export default MHalfs;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { refOnServer } from "../data-names/data";

const initialState = {
  //лоадинги добавил даже не для скелетонов, а просто чтобы не было ошибок
  loadingBorts: "loading",
  loadingCatOfIngred: "loading",
  loadingIngr: "loading",
  loadingPizzaPolos: "loading",
  loadingDrinks: "loading",
  //лоадинги, как уже написано, для проверок. Но, по сути, я мог проверять просто на наличие свойств в объектах и тогда уже что-то делать...
  drinks: [],
  borts: {},
  //ранее, катОфИнгред у нас фетчился (пустой объект) - сейчас я сделал по умолчанию, потому что джсон сервер не дает больше 5 полей, но в будущем будем брать из базы данных, в аппке я фетч закомментирую, проверки в дринксблоке и пиццаблоке - уберу, а еще в мод и халфс, там бордак... проверка нужна в аддингредиентс и всё, но подумай
  catOfIngred: [],
  ingr: {},
  pizzas: [],
  poloPizzas: [],
  pizzaLoadingStatus: "loading",
  redirect: false,
};
/* https://my-json-server.typicode.com/nick-dunne/test-deploy/pizzas */
//выносим логику за пределы View. Делаем запрос на сервер для получения контента - пицц и ниже ингредиентов.
//к слову, в этих асинхронных ТХАНКАХ можно вызывать ДИСПАТЧ (взаимосвязь с другими срезами-слайсами)
export const fetchPizza = createAsyncThunk(
  //ниже название среза/название экшена
  "general/fetchPizza",
  async ({ filter, sorting }) => {
    const baseSorting = sorting
      ? "_sort=" + sorting.cat + "&_order=" + sorting.order
      : "";
    const baseFilter = filter ? "q=" + filter + "&" : "";
    const response = await fetch(
      `${refOnServer}/pizzas?` + baseFilter + baseSorting
    )
      //тут тоже сделал проверку на ок не ок. Обычно не ок, когда адрес другой. Кетч ловит ошибки соединения, в своб очередь... Кстати, второй then реакт не давал мне сделать, поэтому все сделал в одном.
      .then((res) => {
        if (!res.ok) {
          throw new Error("error");
        }
        return res.json();
      })
      //ниже сделали КЕТЧ, потому что без него у промиса ниже не случится РЕДЖЕКТЕД (главное выбрасывать ошибку)
      .catch((e) => {
        throw new Error("Ошибка fetch pizzas");
      });

    return response;
  }
);

export const fetchBorts = createAsyncThunk("general/fetchBorts", async () => {
  const response = await fetch(`${refOnServer}/borts`)
    /* const response = await fetch(
    "https://my-json-server.typicode.com/nick-dunne/test-deploy/borts"
  ) */
    /* const response = await fetch("http://localhost:4000/borts") */
    .then((res) => {
      if (!res.ok) {
        throw new Error("error");
      }
      return res.json();
    })
    .catch((e) => {
      throw new Error("Ошибка fetch borts");
    });

  return response;
});

export const fetchPoloPizzas = createAsyncThunk(
  "general/fetchPoloPizzas",
  async () => {
    const response = await fetch(`${refOnServer}/polo-pizzas`)
      /*    const response = await fetch(
      "https://my-json-server.typicode.com/nick-dunne/test-deploy/polo-pizzas"
    ) */
      /* const response = await fetch("http://localhost:4000/polo-pizzas") */
      .then((res) => {
        if (!res.ok) {
          throw new Error("error");
        }
        return res.json();
      })
      .catch((e) => {
        throw new Error("Ошибка fetch polo-pizzas");
      });
    return response;
  }
);
export const fetchDrinks = createAsyncThunk("general/fetchDrinks", async () => {
  /* const response = await fetch("http://localhost:4000/drinks") */
  /*  const response = await fetch(
    "https://my-json-server.typicode.com/nick-dunne/test-deploy/drinks"
  ) */
  /* const response = await fetch("https://node-tech-server.m2t.pizza/drinks") */
  const response = await fetch(`${refOnServer}/drinks`)
    .then((res) => {
      if (!res.ok) {
        throw new Error("error");
      }
      return res.json();
    })
    .catch((e) => {
      throw new Error("Ошибка fetch polo-pizzas");
    });
  return response;
});

export const fetchIngr = createAsyncThunk("general/fetchIngr", async () => {
  const response = await fetch(`${refOnServer}/ingr`)
    /* const response = await fetch(
    "https://my-json-server.typicode.com/nick-dunne/test-deploy/ingr"
  ) */
    /* const response = await fetch("http://localhost:4000/ingr") */
    .then((res) => {
      if (!res.ok) {
        throw new Error("error");
      }
      return res.json();
    })
    .catch((e) => {
      throw new Error("Ошибка fetch ingr");
    });

  return response;
});
export const fetchCatOfIngred = createAsyncThunk(
  "general/fetchCatOfIngred",
  async () => {
    /* const response = await fetch("http://localhost:4000/catOfIngred") */
    const response = await fetch(`${refOnServer}/catofingred`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("error");
        }
        return res.json();
      })
      .catch((e) => {
        throw new Error("Ошибка fetch catOfIngred");
      });

    return response;
  }
);

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    goRedirect: (state, action) => {
      state.redirect = action.payload;
    },
  },
  //для проработки асинхронных экшенов и нужен экстраредъюсер
  //синтаксис любопытный
  extraReducers: (builder) => {
    builder
      .addCase(fetchBorts.pending, (state) => {
        state.loadingBorts = "loading";
      })
      .addCase(fetchBorts.rejected, (state) => {
        state.loadingBorts = "error";
      })
      .addCase(fetchBorts.fulfilled, (state, action) => {
        state.borts = action.payload;
        state.loadingBorts = "ok";
      })
      //
      .addCase(fetchPoloPizzas.pending, (state) => {
        state.loadingPizzaPolos = "loading";
      })
      .addCase(fetchPoloPizzas.rejected, (state) => {
        state.loadingPizzaPolos = "error";
      })
      .addCase(fetchPoloPizzas.fulfilled, (state, action) => {
        state.poloPizzas = action.payload;
        state.loadingPizzaPolos = "ok";
      })
      //
      //
      .addCase(fetchDrinks.pending, (state) => {
        state.loadingDrinks = "loading";
      })
      .addCase(fetchDrinks.rejected, (state) => {
        state.loadingDrinks = "error";
      })
      .addCase(fetchDrinks.fulfilled, (state, action) => {
        state.drinks = action.payload;
        state.loadingDrinks = "ok";
      })
      //
      .addCase(fetchCatOfIngred.pending, (state) => {
        state.loadingCatOfIngred = "loading";
      })
      .addCase(fetchCatOfIngred.rejected, (state) => {
        state.loadingCatOfIngred = "error";
      })
      .addCase(fetchCatOfIngred.fulfilled, (state, action) => {
        state.catOfIngred = action.payload;
        state.loadingCatOfIngred = "ok";
      })
      //
      .addCase(fetchIngr.pending, (state) => {
        state.loadingIngr = "loading";
      })
      .addCase(fetchIngr.rejected, (state) => {
        state.loadingIngr = "error";
      })
      .addCase(fetchIngr.fulfilled, (state, action) => {
        state.ingr = action.payload;
        state.loadingIngr = "ok";
      })
      //все, что выше - статусы лоадингов - мне нужны для проверок. Я провожу вычисления с данными, пожтому если они не появляются - будут ошибки.
      //ниже логика элегантнее, тут и скелетон и текст ошибки внутри.
      .addCase(fetchPizza.pending, (state) => {
        state.pizzaLoadingStatus = "loading";
      })
      .addCase(fetchPizza.rejected, (state) => {
        state.pizzaLoadingStatus = "error";
      })
      .addCase(fetchPizza.fulfilled, (state, action) => {
        state.pizzas = action.payload;
        state.pizzaLoadingStatus = "ok";
      });
  },
});

//экспортируем обычные экшены
export const { goRedirect } = generalSlice.actions;

//ниже экспортируем редъюсер, чтобы использовать его для формирования стора
export default generalSlice.reducer;

import spinForProductItems from '../../assets/SpinForProductItems.svg';

import { useState, useEffect } from 'react';

export default function ImgWithPreloadSpinner({ alt, src, className }) {
  const [isLoaded, setIsLoaded] = useState(false);

  //ниже добавлен юзЭффект. Когда в компонент, который установил лоадер в тру повторно приходит изображение (случай с половинками) скидывать лоадер в фолс для повторной активации спинера
  useEffect(() => {
    setIsLoaded(false)
  }, [src])

  return (
    //loading: 'lazy' не работает с onLoad
    <>
      {!isLoaded &&
        <img src={spinForProductItems}
          alt={alt}
          style={{ filter: 'none' }}
          className={className} />}
      <img
        className={className}
        style={isLoaded ? null : { display: 'none' }}
        src={src}
        alt={alt}
        onLoad={() => { setIsLoaded(true) }}
        onError={() => { setIsLoaded(true); }}
      />
    </>
  )
}

import ChosenBorts from "../../little/ChosenBorts";
import DescrElemWithDeleteFunc from "../../mod-pizza/delete-ingredients/DescrElemWithDeleteFunc";
import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import ListOfAddIngrWithDeleteFunc from "../../mod-pizza/add-ingredients/ListOfAddIngrWithDeleteFunc";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";

import { useNavigate, useOutletContext, Link } from "react-router-dom";


const MHalfsDelete = () => {
  const {
    refForClickOutside1,
    showFirstPolo,
    setShowFirstPolo,
    firstPolo,
    firstPizzasPolo,
    refForClickOutside2,
    showSecondPolo,
    setShowSecondPolo,
    secondPolo,
    secondPizzasPolo,
    borts,
    setBortIndex,
    bortIndex,
    ingr,
    deletedIngrForOne,
    deletedIngrForTwo,
    setDeletedIngrForOne,
    setDeletedIngrForTwo,
    setAddToAll,
    setAddToFirst,
    setAddToSecond,
    addToAll,
    countOfExtras,
    extraIngr,
    setExtraIngr,
    addToFirst,
    namePoloOne,
    namePoloTwo,
    extraIngrFirst,
    extraIngrSecond,
    setExtraIngrSecond,
    setExtraIngrFirst,
    addToSecond,
    modPrice,
    countModPizza,
    setCountModPizza,
    fullId,
    firstPoloImg,
    secondPoloImg,
  } = useOutletContext();

  const navigate = useNavigate();
  return (
    <div
      className="m-modal"
    >
      <div className="m-halfs">
        <Link to={'/halfs'}><div className="m-modal__close">
          ⟵
        </div>
        </Link>
        <span className="halfs__descrFirstPolo-title">
          Cклад {firstPolo.name}:
        </span>
        <ul className="halfs__descrFirstPolo">
          <DescrElemWithDeleteFunc
            descr={firstPolo.descr}
            ingr={ingr}
            deletedIngr={deletedIngrForOne}
            setDeletedIngr={setDeletedIngrForOne}
          />
        </ul>
        <span className="halfs__descrSecondPolo-title">
          Cклад {secondPolo.name}:
        </span>
        <ul className="halfs__descrSecondPolo">
          <DescrElemWithDeleteFunc
            descr={secondPolo.descr}
            ingr={ingr}
            deletedIngr={deletedIngrForTwo}
            setDeletedIngr={setDeletedIngrForTwo}
          />
        </ul>
        <button
          className="m-addIngredients__reset-mod"
          onClick={() => {
            setDeletedIngrForOne({});
            setDeletedIngrForTwo({});
          }}
        >
          Очистити весь вибір
        </button>

        <Link className="m-addIngredients__submit" to={'/halfs'}>
          Завершити видалення інгредієнтів
        </Link>

      </div>
    </div>
  );
};

export default MHalfsDelete;

import useMedia768 from "../../hooks/useMedia";
import { Link } from 'react-router-dom';
import cart from '../../assets/cart.png'
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { calculateSpecialPromoPrice } from "../../features/cartSlice";

import './mFixCard.scss';

const MFixCard = () => {
  const specialPromoPrice = useSelector(state => state.cart.specialPromoPrice);
  const totalPrice = useSelector((state) => state.cart.totalPriceCart);
  const totalCount = useSelector((state) => state.cart.totalCountCart);
  const isMobile = useMedia768();
  const activePromocode = useSelector(state => state.cart.activePromocode);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(calculateSpecialPromoPrice())
  }, [activePromocode?.title])


  return (
    <>
      {/* //будем на мобильных показывать кнопку корзины внизу экрана */}
      {isMobile && (totalCount > 0) ?

        <Link to='/cart'>
          <div className="fixed-cart">
            <div className="fixed-cart__colone">
              <span>{totalCount}</span>
              <img src={cart} alt="" />
            </div>

            <span className="fixed-cart__price">{specialPromoPrice ?? totalPrice} грн</span>
          </div>
        </Link>
        : null}
    </>
  )
}

export default MFixCard;

import { useEffect, useRef } from "react";

export default function useComponentVisible(state, setState) {
  const refForClickOutside = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refForClickOutside.current &&
      !refForClickOutside.current.contains(event.target)
    ) {
      setState(false);
    }
  };

  useEffect(() => {
    if (state) {
      document.addEventListener("click", handleClickOutside, { passive: true });
      return () => {
        document.removeEventListener("click", handleClickOutside, {
          passive: true,
        });
      };
    }
  }, [state]);

  return refForClickOutside;
}

const ChosenBorts = ({ bortIndex, setBortIndex, borts }) => {
  return (
    <div className="modal__borts">
      {Object.keys(borts).map((item) => {
        return (
          <span
            className={
              item === bortIndex
                ? "modal__borts-item modal__borts-item-act"
                : "modal__borts-item"
            }
            key={item}
            onClick={() => {
              setBortIndex(item);
            }}
          >
            {borts[item].title}
            <br></br>
            <span>борт</span>{" "}
          </span>
        );
      })}
    </div>
  );
};

export default ChosenBorts;

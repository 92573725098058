import coupon from '../../assets/promotion/coupon450.webp';
import freeday from '../../assets/promotion/freeday450.webp';
import promocode from '../../assets/promotion/promocode450.webp';

import ImgWithPreloadSpinner from '../../components/little/ImgWithPreloadSpinner';

import { useState, useEffect } from 'react';

import './promotion.scss'
import '../pages.scss'

const MPromotions = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  const [seeMoreCoupons, setSeeMoreCoupons] = useState(false);
  const [seeMoreFreeday, setSeeMoreFreeDay] = useState(false);
  const [seeMorePromocode, setSeeMorePromocode] = useState(false);
  return (

    <div className="promotion">
      <div className="container">
        <h1 className="pages__title">
          Акції
        </h1>
        <div className="promotion__block promotion__block--m">
          <ImgWithPreloadSpinner src={coupon} alt="coupons" className="pages__img promotion__img promotion__img--m" />
          <div className="promotion__content">
            <h2 className="promotion__title promotion__title--m">Збери 10 купонів та отримай піцу "безкоштовно"</h2>
            <div className="promotion__descr promotion__descr--m">
              Cтрок дії акції - безстрокова
            </div>
            <p
              onClick={() => { setSeeMoreCoupons(!seeMoreCoupons) }}
              className="promotion__more promotion__more--m">Читати про умови{seeMoreCoupons ? '▲' : '▼'} </p>
            {seeMoreCoupons ?

              <div className="promotion__text promotion__text--m"><br />Купуєте піцу – зберігаєте купони.<br />
                Зібравши 10 купонів, особа має право прийти безпосередньо до закладу М2Т (ТЦ «Добропілля») та обміняти відповідну кількість купонів на будь-яку піцу, яка є в асортименті.
                <br />Якщо ж особа бажає замовити доставку, то до «безкоштовної» піци потрібно буде замовити хоча б одну піцу додатково.
                <br /><br />
                Варто звернути увагу, що купон знаходиться на боковій стороні фірмової коробки для піци.
              </div>
              : null
            }
          </div>
        </div>
        <div style={{ 'clear': 'both' }}></div>

        <div className="promotion__separator promotion__separator--m"></div>

        <div className="promotion__block promotion__block--m">
          <ImgWithPreloadSpinner src={freeday} alt="coupons" className="pages__img promotion__img promotion__img--m" />
          <div className="promotion__content promotion__content--m">
            <h2 className="promotion__title promotion__title--m">Дні безкоштовної доставки</h2>
            <div className="promotion__descr promotion__descr--m">
              Cтрок дії акції оголошується додатково
            </div>
            <p
              onClick={() => { setSeeMoreFreeDay(!seeMoreFreeday) }}
              className="promotion__more promotion__more--m">Читати про умови{seeMoreFreeday ? '▲' : '▼'} </p>

            {seeMoreFreeday ? <div className="promotion__text promotion__text--m"><br />Якщо у звичайні дні наша доставка є платною, то в дні безкоштовної доставки будь-яке замовлення буде доставлено безкоштовно. Звісно, потрібно буде заплатити лише за сам замовлений продукт. =)
              <br /><br />
              Про акцію може бути оголошено зненацька на один чи кілька конкретних днів, тому радимо підписатися на наш <a className='promotion__smlink' target='_blank' href='https://www.instagram.com/m2t.pizza/'>Інстаграм @m2t.pizza</a>, щоб бути в курсі всіх наших івентів.
            </div> : null}
          </div>
        </div>

        <div style={{ 'clear': 'both' }}></div>
        <div className="promotion__separator promotion__separator--m"></div>

        <div className="promotion__block promotion__block--m">
          <ImgWithPreloadSpinner src={promocode} alt="coupons" className="pages__img promotion__img promotion__img--m" />
          <div className="promotion__content promotion__content--m">
            <h2 className="promotion__title promotion__title--m">Промокоди на знижку</h2>
            <div className="promotion__descr promotion__descr--m">
              Cтрок дії акції оголошується додатково
            </div>
            <p
              onClick={() => { setSeeMorePromocode(!seeMorePromocode) }}
              className="promotion__more promotion__more--m">Читати про умови{seeMorePromocode ? '▲' : '▼'} </p>

            {seeMorePromocode ? <div className="promotion__text promotion__text--m"><br />Час від часу ми випускаємо спеціальні промокоди у формі певних тематичних слів. Наприклад - "m2t", "pepperoni" тощо.
              <br /><br />
              Якщо вписати акутальний промокод у спеціальу форму на сайті й активувати, то застосується певна процентна знижка на всю суму замовлення. Приємна вигода.)
              <br /><br />
              Варто мати на увазі, що задіяти промокод можливо лише через сайт піцерії.
              <br /><br />
              Про появу чергового промокоду може бути оголошено зненацька, тому радимо підписатися на наш <a className='promotion__smlink' target='_blank' href='https://www.instagram.com/m2t.pizza/'>Інстаграм @m2t.pizza</a>, щоб точно не пропустити таку новину.
            </div> : null}
          </div>
        </div>
      </div>
    </div >

  )
}

export default MPromotions;

import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import { useOutletContext, Link, useLocation } from "react-router-dom";

const MModPizzaAdd = () => {
  const location = useLocation().pathname;
  const pathToRedirect = location.substring(0, location.lastIndexOf('/'));

  const { img, extraIngr, setExtraIngr, priceForOne } = useOutletContext();
  return (
    <div className="m-modal">
      <div className="m-modal__content">

        <Link to={pathToRedirect}>
          <div
            className="m-modal__close"
          >
            ⟵
          </div>
        </Link>

        <div className="m-modal-wr">
          <img src={img} alt="#" className="m-modal__img" />
          <div className="m-modal__wrapper">
            <AddIngredients
              priceForOne={priceForOne}
              extraIngr={extraIngr}
              setExtraIngr={setExtraIngr}
              isUsualPizza={true}
              pathToRedirect={pathToRedirect}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MModPizzaAdd;

const ListOfAddIngrWithDeleteFunc = ({extraIngr, setExtraIngr, ingr})=>{
        //ниже работаем со списком добавленных ингредиентов, перебор - создание верстки
        const extraElements = Object.keys(extraIngr).map((item, index, arr)=>{
            return (
                <li key={item+index+66}>
                    <span>{ingr[item][0]} ({extraIngr[item].length})</span>
                    <button
                    style={{lineHeight: '12px'}}
                    onClick={()=>{setExtraIngr(()=>{
                        let copyObj = {...extraIngr};
                        delete copyObj[item];
                        return copyObj;
                    })}}>✖</button>
                    {index === arr.length-1 ? null : ','}
                </li> 
            )
        })

        return (
            <>
            {extraElements}
            </>
        )
}

export default ListOfAddIngrWithDeleteFunc;
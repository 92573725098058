//компонент поднимается из Апп.джс

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

//имортируем наш кастомный хук для определения ширины экрана
import useMedia768 from "../../hooks/useMedia";

import useExtrasPriceSum from "../../hooks/useExtrasPriceSum";

import { useParams, Outlet } from "react-router-dom";

import "./modPizza.scss";
import "./m-modPizza.scss";
import PCModPizza from "./pc/PCModPizza";

const ModPizza = () => {

  //получаем параметры http запроса, ранее я использовал модифайАЙди, которое получал из редакс стэйта, но пока что так, чтобы была история...
  const params = useParams();
  const isMobile = useMedia768();
  //создам еще дополнительный стэйт для добавления ингредиентов (мобильная версия онли)
  const [isMobileWantAdd, setIsMobileWantAdd] = useState(false);

  //здесь используются бортики, поэтому будем формировать их локально
  const [bortIndex, setBortIndex] = useState("0");
  //для описания пиццы мы используем числовые обозначения, которые в последствии преобразуем в слова, используя объект из базы данных
  const ingr = useSelector((state) => state.general.ingr);
  //получаем борты также из базы данных
  const borts = useSelector((state) => state.general.borts);
  //получаем массив с пиццами из базы данных
  const pizzaData = useSelector((state) => state.general.pizzas);
  //ниже создаем локальный РЕАКТ СТЭЙТ, чтобы сюда помещать ингредиенты, которые УДАЛЕНЫ. Это отдельная информация, которая в дальнейшем будет формировать уникальный АЙДИ для объекта в корзине, а также будет использоваться в верстке последней.
  const [deletedIngr, setDeletedIngr] = useState({});
  //ниже создаем локальный РЕАКТ СТЭЙТ, чтобы сюда помещать ингредиенты, которые ДОБАВЛЕНЫ. Это отдельная информация, которая будет формировать уникальный АЙДИ для объекта в корзине, а также будет использоваться в верстке последней.
  const [extraIngr, setExtraIngr] = useState({});
  //ниже мы также создадим стэйт для счетчика количества пицц, которое мы добавим в корзину
  const [countModPizza, setCountModPizza] = useState(1);

  //когда открывается модальное окно, мы будем убирать скролл с основной части сайта. При размонтировании скрол будет снова добавляться.
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const extrasPriceSum = useExtrasPriceSum(extraIngr);

  //ниже моя уродливая проверка на то, получены ли элементы ()
  const loadingBorts = useSelector((state) => state.general.loadingBorts);
  const loadingCatOfIngred = useSelector(
    (state) => state.general.loadingCatOfIngred
  );
  const loadingIngr = useSelector((state) => state.general.loadingIngr);
  const pizzaLoadingStatus = useSelector(
    (state) => state.general.pizzaLoadingStatus
  );
  //ниже проверка - если произошла ошибка в фетчинге, то вернет пустоту (ведь с этими сущностями мы произодим проверки)
  if (
    loadingBorts !== "ok" ||
    loadingIngr !== "ok" ||
    pizzaLoadingStatus !== "ok"
  ) {
    return null;
  }
  //проверка окончена

  //деструктурируем и получаем всю необходимую информацию о пицце для верстки. Вытаскиваем все из конкретной пиццы, айди которой нам приходит из параметров запроса - params.pizzaId
  const [{ id, img, name, price, descr, category, weight }] = pizzaData.filter(
    (item) => item.id == params.pizzaId
  );
  //ниже условия (если есть определенный элемент массива, то показываем определенный контент, если нет, то ничего)
  const isNew = category.includes("new") ? (
    <span className="pizzablock__isNew">NEW</span>
  ) : null;
  const isHit = category.includes("hit") ? (
    <span className="pizzablock__isHit">ХІТ</span>
  ) : null;
  const isVegan = category.includes("vegan") ? (
    <span className="pizzablock__special">ВЕГАН</span>
  ) : null;
  const isHot = category.includes("hot") ? (
    <span className="pizzablock__isHot">HOT</span>
  ) : null;

  //ниже формируем айди, которое будет создавать в корзине уникальную запись, преобразовываем объект в массив, чтобы можно было сортировать. Сортируем для того, чтобы последовательность всегда была одинаковой. Ведь, если из модального окна в корзину дважды будет добавлена пицца без сыра, то в корзине должен быть только один массив с двумя объектами такой пиццы. То есть запись одна, а количество равное двум. **Все это из-за того, что порядок свойств объекта при переборе может случайным образом измениться - это ведь объект, а не массив. Поэтому может случиться так, что будут добавлены две одинаковые пиццы с одинаковыми дополнительными ингредиентами, однако получат разные айди. Поэтому сортируем.

  const fullId =
    id +
    bortIndex +
    descr.join("") +
    Object.keys(deletedIngr)
      .sort((a, b) => a - b)
      .join("") +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .join("") +
    Object.keys(extraIngr)
      .sort((a, b) => a - b)
      .map((item) => extraIngr[item].length)
      .join("");

  //ниже считаем цену финальную
  const modPrice =
    (price + extrasPriceSum + borts[bortIndex].price) * countModPizza;
  const priceForOne = (price + extrasPriceSum + borts[bortIndex].price);

  //далее идёт то, что непосредственно возвращает компонент - jsx
  //оутлеты нужны для подроутинга, чтобы все было самостоятельное... если в ПК версии нужны будут подроутинги, то можно и туда Оутлет
  if (isMobile) {
    return (
      <Outlet context={{ isMobileWantAdd, setIsMobileWantAdd, img, name, weight, isNew, isHit, isHot, isVegan, deletedIngr, setDeletedIngr, descr, ingr, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, modPrice, countModPizza, setCountModPizza, fullId, priceForOne }} />
    )
  } else {
    return (<PCModPizza props={{ deletedIngr, setDeletedIngr, descr, ingr, name, weight, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, modPrice, countModPizza, setCountModPizza, fullId, img }} />)
  }

};

export default ModPizza;

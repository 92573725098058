function DescrElemWithDeleteFunc({ deletedIngr, setDeletedIngr, descr, ingr }) {
  //ниже формируем локальный стэйт удаленных ингредиентов, чтобы потом эту информацию использовать для получения уникального айди и отправить все это дело в стэйт корзины. Здесь после нажатия на триггер определенного ингредиента передается его порядковый номер, который я помещаю в объект. Выбрал объект, потому что с ним проще работать в данном случае. Проще в плане удаления, на первый взгляд, на мой взгляд.
  const formStateWithDeletedIngr = (deletedIngrName) => {
    //если убираемый порядковый номер уже есть в объекте, то мы это свойство удаляем. Получается фактически ТОГЛ
    if (deletedIngrName in deletedIngr) {
      let copyObj = { ...deletedIngr };
      delete copyObj[deletedIngrName];
      setDeletedIngr(copyObj);
    } else {
      //иначе мы добавляем новое ключ-значение, соблюдая принципы иммутабельности
      setDeletedIngr({ ...deletedIngr, [deletedIngrName]: deletedIngrName });
    }
  };

  //ниже работаем с описанием пиццы (порядковые номера), которое будем перебирать и создавать необходимую нам верстку
  const descrElements = descr.map((item, index, arr) => {
    //класс даем удаленному элементу в зависимости - есть ли такой ингредиент в объекте удаленных объектов (сорри за формулировку)
    let classForDeletedIngr = "modal__pizza-ingr-to-del";
    if (item in deletedIngr) {
      classForDeletedIngr = "modal__pizza-deleted";
    }
    return (
      <li
        id={item}
        //при клике на элемент и будет происходить наполение объекта с удаленными ингредиентами
        onClick={(e) => {
          formStateWithDeletedIngr(e.currentTarget.id);
        }}
        key={item + index + 34}
      >
        <span className={classForDeletedIngr}>
          {/* ingr[item][0] - из-за структуры моей базы данных */}
          {ingr[item][0]}
        </span>{" "}
        <button style={{ lineHeight: "12px" }}>
          {item in deletedIngr ? "⟳" : "✖"}
        </button>
        {index === arr.length - 1 ? null : ", "}
      </li>
    );
  });

  return <>{descrElements}</>;
}

export default DescrElemWithDeleteFunc;

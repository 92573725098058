import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import useMedia768 from '../../hooks/useMedia';
import place from '../../assets/placing.png';
import BurgerMenu from './burgerMenu/BurgerMenu.jsx';

import './headerAdd.scss';
import './burgerBtn.scss';
//
const Header = () => {
  const isMobile = useMedia768();
  const activeStyle = { color: "white" };
  const [menuBurger, setMenuBurger] = useState(false);

  return (
    <>
      <header className='header'>
        <div className="container">
          <div className="header__wrapper">
            <div className='header__inf'><a href="tel:+38 099 3965 777">+38 099 3965 777</a>
              <div className='header__city-ico-wr'>
                <img className='header__place-icon' src={place} alt="-" />
                <span className="header__city"
                  aria-hidden="true">Добропілля</span>
              </div>
              <div className="header__worktime">10:00-18:00</div>
            </div>

            {!isMobile ?
              <ul className="header__menu">

                <li><NavLink
                  to="/"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>Головна</NavLink></li>
                <li><NavLink
                  to="/about"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>Про нас</NavLink></li>
                <li><NavLink
                  to="/promotion"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>Акції</NavLink></li>
                <li><NavLink
                  to="/legend"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }>Легенда</NavLink></li>


              </ul>
              :
              <>
                <div
                  onClick={() => { setMenuBurger(!menuBurger) }}
                  className={menuBurger ? `burger-btn--active` : `burger-btn`}>
                  <span className={menuBurger ? `burger-btn--active__indicator--active` : `burger-btn__indicator`}></span>
                </div>
                <BurgerMenu menuBurger={menuBurger} setMenuBurger={setMenuBurger} />

              </>
            }
          </div>
        </div>
      </header>




    </>
  )
}

export default Header;

import { useState, useEffect } from 'react';
//важно обозначить, что поведение меняется в зависимости от того, существет ли nonclickRef.current и того, что я возвращаю из хука... поубирай проверки, поєкспериментируй с тем, что возвращает скролтопфорєсайд и может быть узнаешь, в чем дело...
//не работает как положено, дело в рендерах... будем искать потом

const defaultTop = 71;
const heightOfStickySecondHeader = 70;

const useCustomScroll = (nonClickRef, path) => {


  const [scrollTopForAside, setScrollTopForAside] = useState(defaultTop);
  const [prevScroll, setprevScroll] = useState(window.pageYOffset);





  //ниже страховка от резкой прокрутки вверх
  useEffect(() => {
    if (scrollTopForAside > defaultTop) {
      setScrollTopForAside(defaultTop)
    }
  }, [scrollTopForAside > defaultTop])
  //ок


  //
  const whenScrollWindow = () => {

    if (nonClickRef.current) {

      const asideCartParams = nonClickRef.current.getBoundingClientRect();

      if ((asideCartParams.height > window.innerHeight - heightOfStickySecondHeader)) {

        const visibleHeight = Math.min(asideCartParams.bottom, window.innerHeight) - Math.max(asideCartParams.top, 0);

        const scrollTop = window.pageYOffset;
        let pxMove = scrollTop - prevScroll;
        setprevScroll(prevScroll + pxMove);
        //благодаря тому, что выше мы получаем единицу смещения и функционал для понимания идем вверх или вниз

        //листание вверх
        if (scrollTop < prevScroll) {
          if (scrollTopForAside < defaultTop) {
            //увеличиваем топ
            setScrollTopForAside(scrollTopForAside => scrollTopForAside - pxMove)
          }
        }
        //листание вниз
        else {
          if (
            visibleHeight > window.innerHeight * 0.7)
          //0.7 это просто с воздуха
          {
            //уменьшаем топ
            setScrollTopForAside(scrollTopForAside => scrollTopForAside - pxMove);

          }
        }
      }
      else {
        setScrollTopForAside(defaultTop)
      }
    }
  }
  useEffect(() => {

    window.addEventListener('scroll', whenScrollWindow);

    //ниже страховка от резкой прокрутки вниз
    if (nonClickRef.current) {
      const asideCartParams = nonClickRef.current.getBoundingClientRect();
      if (asideCartParams && scrollTopForAside < 0 && Math.abs(scrollTopForAside) > asideCartParams.height * 0.7) {
        setScrollTopForAside((prevState) => {
          //160 это вроде высота всех хедеров с запасом...
          return Math.max((asideCartParams.height - window.innerHeight + 160) * -1, prevState)
        })

      }
    }
    //выше страховка от резкой прокрутки вниз
    return () => { window.removeEventListener('scroll', whenScrollWindow) };


  }, [prevScroll, scrollTopForAside])
  //скролл для эсайда закончен

  if (path === '/order') {
    return defaultTop;
  }


  return scrollTopForAside;
}

export default useCustomScroll;
/*
ниже была моя попытка сделать, чтобы при добавлении в корзину происходил скрол вниз. Условие всегда выполняется, топ изменяется, но вврех почему-то не поднимает...


const [cartItems, setCartItems] = useState(Object.keys(cartData).length);

useEffect(() => {


  //эксперимент
  if (nonClickRef.current) {
    if (Object.keys(cartData).length > cartItems) {
      const asideCartParams = nonClickRef.current.getBoundingClientRect();
      const visibleHeight = Math.min(asideCartParams.bottom, window.innerHeight) - Math.max(asideCartParams.top, 0);
      if ((asideCartParams.height > window.innerHeight - 70)) {

        if ((visibleHeight >= (window.innerHeight - 70))  && scrollTopForAside <= 71 ) {

            console.log({ vh: visibleHeight, wh: window.innerHeight - 70 })
            console.log('условие выполняется')

          setScrollTopForAside(-(asideCartParams.height - (window.innerHeight + 160)))
          console.log(`вычитается от asideCartParams.height ${asideCartParams.height} window.innerHeight + 160 ${window.innerHeight + 160} и превращается в отрицательное число = ${asideCartParams.height - window.innerHeight + 160}`)

          console.log('все постоянно откликается')
        }

      }
    }
  }
  setCartItems(Object.keys(cartData).length);
  //єксперимент окончен
}, [cartData]) */

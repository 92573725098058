import { useRef, useEffect } from "react";

export default function FormClarifyPay({ props }) {
  const { data, signature, goRedirect, fetchingToTelegram, wishCall, setError, dispatch, fetchToUpdateUsedPromo, resetAllCart, error } = props;

  const ref = useRef(null);
  useEffect(() => {

    return () => {
      dispatch(goRedirect(false));
    }
  }, [])

  return (
    <div className="order__redirect">
      <form
        className='order__redirect-form'
        ref={ref}
        method="POST" action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8">
        <input type="hidden" name="data" value={data} />
        <input type="hidden" name="signature" value={signature} />

        {
          error ?
            <div style={{ color: 'red' }}>Щось пішло не по плану. Перевірте з'єднання або спробуйте зробити замовлення без оплати</div>
            :
            <div className='order__redirect-text'>Далі вас буде переадресовано на сторінку оплати.
              {wishCall ? <span style={{ display: 'block', marginTop: '10px' }}>Після успішного перерахування коштів - ми зв'яжемось з вами протягом 15 хвилин задля уточнення замовлення.</span> : null}
            </div>
        }

        <div className="order__redirect-btns">
          <button
            onClick={() => {
              dispatch(goRedirect(false));
              setError(false);
            }}
            className='order__redirect-backbtn'
          >Назад</button>

          <button
            className='order__redirect-gobtn'
            onClick={(e) => {
              e.preventDefault();
              fetchingToTelegram()
                .catch(error => {
                  setError(true);
                })
                .then(res => {
                  if (!res.ok) {
                    setError(true);
                  }
                  else {
                    dispatch(resetAllCart());
                    fetchToUpdateUsedPromo();
                    ref.current.submit();
                  }
                });
            }}
          >Перейти до оплати</button>

        </div>


      </form>


    </div>
  )
}


import AsideCart from "../components/aside-cart/AsideCart";

import { Outlet } from 'react-router-dom';

import Pizzablock from "../components/pizzablock/Pizzablock";
import DrinkBlock from "../components/drink-block/DrinkBlock";
import MFixCard from "../components/m-fix-card/MFixCard";
import Order from "../components/order/Order";
import { useLocation } from "react-router-dom";


const Main = () => {

  const path = useLocation().pathname;

  if (path === '/order') {
    return (
      <section className="main">
        <div className="container">
          <div className="main-wrapper">
            <div className="main-block">
              <Order />
            </div>
            <AsideCart />
          </div>
        </div>
        <Outlet />
      </section>
    )
  }

  return (
    <section className="main">
      <div className="container">
        <div className="main-wrapper">
          <div className="main-block">
            <Pizzablock />
            <DrinkBlock />
          </div>
          <AsideCart />
        </div>
      </div>
      <Outlet />
      <MFixCard />
    </section>
  )
}

export default Main;

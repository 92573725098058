import pic1 from '../assets/legend/pic1.jpg';
import pic2 from '../assets/legend/pic2.jpg';
import pic3 from '../assets/legend/pic3.jpg';
import pic4 from '../assets/legend/pic4.jpg';
import picHarry from '../assets/legend/harry.webp';

import ImgWithPreloadSpinner from '../components/little/ImgWithPreloadSpinner';

import { useEffect } from 'react';

import './legend.scss';
const Legend = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="legend">
      <div className="container">
        <h1 className="legend__title">Хто або що таке "М2Т"?</h1>
        <div className="legend__text">
          Напевно, щоб відповісти на питання – «хто або що таке М2Т?», спочатку потрібно розповісти невеличку історію про одну людину.
          <br /><br />
          Що ж…
          <br /><br />
          Гаррі – це чоловік середнього віку, невисокого зросту, худорлявий, з темним волоссям та карими очима, які постійно сховані за чудернацької форми окулярами.
          Живе Гаррі у маленькому містечку в двоповерховому будинку, який отримав у спадок від своєї прабабусі. Працює якось «в інтернеті», великих грошей не має, але на холостяцьке життя вистачає сповна.
          <br /><br />
          Втім, це не головне.
          <br /><br />
          Важливим для цієї історії є його нестримна цікавість до різних винаходів, велика любов до наукової фантастики та «золоті» руки.
          Бувало таке, що побачить у кіно якийсь цікавий прилад й одразу намагається його відтворити у своїй майстерні.
          <br /><br />
          Наприклад, колись Гаррі дуже захоплювався фільмами про Джеймса Бонда. Так от, уявіть собі, після цього «хоббі» у нього вдома з’явився окремий стелаж, в якому нині зберігається весь арсенал агента «007». При цьому варто відзначити, що чоловік сам повторив й навіть трохи вдосконалив купу різних шпіонських пристроїв, починаючи від ручки-ракети, закінчуючи окулярами-рентгеном.
          <ImgWithPreloadSpinner src={picHarry} alt="Harry - the creator" className="legend__present-img" />
          <span className='legend__descr-img'>Гаррі</span>
          Що тут скажеш - любить Гаррі щось вигадувати та майструвати... Однак на цьому його розваги не закінчуються, адже ще він дуже любить смачно та ситно попоїсти. На його думку, жодна робота не може бути виконана або розпочата на голодний шлунок. Він справжній фанат італійської кухні, а піца – така ж його пристрасть, як і винаходи.
          <br /><br />
          Час від часу Гаррі замовляв піцу в одному з місцевих ресторанів, однак через велику вартість такої продукції, він не міг собі дозволити робити це так часто, як йому того хотілося. Внаслідок цього він нерідко намагався готувати бажану страву самостійно, але тут траплялися несподіванки.
          Оскільки мозок Гаррі постійно зайнятий думками про нові винаходи, чоловік регулярно забував своєчасно витягувати піцу з духової шафи, через що остання була або занадто підсмажена, або зовсім спалена.
          <br /><br />
          Та то нічого, якщо б це траплялося рідко, але ж коли це стало чи не щоденною проблемою – він задумався: чи не створити йому робота, який міг би контролювати процес приготування улюбленої страви від А до Я? <br />
          Отакої! А якщо Гаррі забажав щось створити – його хлібом не годуй, а дай скоріше креслення та інструменти.
          <ImgWithPreloadSpinner src={pic1} alt="early drawings" className="legend__present-img" />
          <span className='legend__descr-img'>перші нариси</span>
          <ImgWithPreloadSpinner src={pic2} alt="early drawings" className="legend__present-img" />
          <span className='legend__descr-img'>пошук кращої концепції</span>
          Таким чином й було зроблено робота, тіло якого складалося з коробок для піци (витримано тематику), замість рук винахідник прилаштував йому металеві клешні, замість ніг – колесо, а на голові було закріплено антену (для координації рухів в тому числі).
          <br /><br />
          Коли механічна складова нового винаходу була виконана ідеально, майстер почав думати про те, як запрограмувати робота правильно готувати піцу.
          Попрацювавши трохи з платами та чіпами робота, чоловік відкрив останньому доступ в інтернет та налаштував на постійне самонавчання. Після таких маніпуляцій створіння було відправлену у шафу на декілька тижнів. Внаслідок цього, через сотні годин збору інформації, робот знав про приготування піци більше, ніж будь-який кухар у всьому всесвіті.
          <br /><br />
          Так і було «народжено» першого робота-піцайолу.
          <ImgWithPreloadSpinner src={pic3} alt="Finalized image" className="legend__present-img" />
          <span className='legend__descr-img'>завершене векторне зображення</span>
          Безумовно, новому створінню було потрібне ім’я, тож Гаррі, довго не думаючи, назвав його просто «М2Т». На питання «чому М2Т?» - чоловік відповідав ще простіше – «тому що!». <br />
          Ну, а якщо ж його починали нав'язливо розпитувати, то він згадував дроїда із всесвіту «Зоряних війн» (Р2 -Д2) та говорив, що роботам дозволяється мати дивні імена. Що ж, тут не посперечаєшся…
          <br /><br />
          М2Т вийшов у Гаррі справжнім генієм з виготовлення піци. Робот зібрав у своїй пам’яті лише кращі рецепти та кожного разу готував для свого творця вишукану страву, якість якої була беззаперечною та, що важливо, постійною, адже «машина» ніколи не відступає від технологічних карт.
          <br /><br />
          Але не тільки в готуванні був придатний М2Т. Оскільки замість ніг у нього було колесо, він міг дуже швидко доставити приготовану піцу для Гаррі, де б він не знаходився й коли б він її не забажав. Згодом й всі сусіди, що мешкали неподалік, були у захваті від чудо-створіння. Спочатку весь провулок, а потім й вся вулиця почала робити замовлення у Гаррі, а робот лише й встигав їздити від будинка до будинку й доставляти людям радість у вигляді гарячої та смачної італійської страви.
          <br /><br />
          Звідси, власне, й пішов лозунг – «якщо піца, то лише від М2Т».
          <br /><br />
          Проте… Через деякий час робот зник. <br />
          Кажуть, що Гаррі вирішив заробити на своєму винаході та прийняв рішення продати М2Т одному неприємному багатію, який хотів готувати піцу з дешевих інгредієнтів в комерційних цілях. Однак плани «ділків» не було реалізовано.
          <br /><br />
          Так вийшло, що Гаррі забув закрити роботу доступ в інтернет й через це, коли М2Т вивчив всі матеріали про піцу, він почав розвиватися в абсолютно всіх напрямах життя… Таким чином, чудо-створіння набуло якоїсь подоби свідомості й, коли Гаррі сповістив його про продаж, робот образився на власного творця та сказав, що він не річ, щоб його продавати…
          <br /><br />
          Після цього М2Т забрав своє запасне колесо й усі свої заощадження та поїхав у невідомому напрямку. Де він зараз – ніхто не знає. Але подейкують, що він досі займається улюбленою справою в якомусь іншому місці. Начебто він відкрив свою піцерію й, як і раніше, дарує людям гастрономічне задоволення.
          <ImgWithPreloadSpinner src={pic4} alt="Real photo" className="legend__present-img" />
          <span className='legend__descr-img'>реальне фото 2021 року ;)</span>
        </div>
      </div>
    </section>
  )
}

export default Legend;

import "./aside-cart.scss";

import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { resetAllCart } from "../../features/cartSlice";

import AsideCartItem from "./asideCartItem/AsideCartItem";
import CartPng from '../../assets/cart.png';
import PromoBlock from "../little/promoBlock/PromoBlock";

import useCustomScroll from "../../hooks/useCustomScroll";

const AsideCart = () => {

  const activePromocode = useSelector(state => state.cart.activePromocode);

  //useLocation это хук, чтобы получить доступ к урл. Мы его будем использовать для условного рендеринга кнопки "оформить". Это реакт-роутер
  const path = useLocation().pathname;
  const cartData = useSelector((state) => state.cart.cart);

  const totalCountCart = useSelector((state) => state.cart.totalCountCart);
  const totalPriceCart = useSelector((state) => state.cart.totalPriceCart);

  const loadingBorts = useSelector((state) => state.general.loadingBorts);
  const loadingCatOfIngred = useSelector(
    (state) => state.general.loadingCatOfIngred
  );
  const loadingIngr = useSelector((state) => state.general.loadingIngr);
  const loadingDrinks = useSelector(state => state.general.loadingDrinks);
  const loadingPizzaPolos = useSelector(state => state.general.loadingPizzaPolos);
  const pizzaLoadingStatus = useSelector(state => state.general.pizzaLoadingStatus);

  //когда страница для редиректа на ликпєй активна, будем отключать возможность клацания на корзину
  const nonClickRef = useRef(null);
  const redirect = useSelector((state) => state.general.redirect);
  if (nonClickRef.current && redirect) {
    nonClickRef.current.style.pointerEvents = "none";
  } else if (nonClickRef.current) {
    nonClickRef.current.style.pointerEvents = "";
  }

  //Ниже скролл для єсайда...его бы вынести куда-то
  const scrollTopForAside = useCustomScroll(nonClickRef, path);
  //скролл для эсайда закончен


  //выбрал компонент ЭСАЙД-КАРТ, который пристально следит за РЕДАКС-СТЭЙТОМ корзины.
  //когда данные в корзине обновляются, мы бросаем тот объект в локалСторэйдж.
  //при этом мы делаем проверку, чтобы при первом монтировании (по умолчанию РЕДАКС-СТЭЙТ обнуляется), наш локалСторэйдж не обновлялся пустым объектом корзины.
  //в то же время в картСлайсе мы установим в качестве инициального значения данные с локалСторэйдж, либо, если их нет, то будет просто пустой объект.
  //используем юзРеф, поскольку, оказыается, его можно использовать не только для того, чтобы обращаться к ДОМ эелементами, а и для того, чтобы просто хранить мутабельные данные, изменение которых, однако, не вызывает перерендера.
  let wasFirstMount = useRef(false);
  useEffect(() => {
    if (wasFirstMount.current) {
      localStorage.setItem("cart", JSON.stringify(cartData));
    }
    wasFirstMount.current = true;
  }, [cartData]);
  //корзина в локал сторєйдж - сделано






  const dispath = useDispatch();

  const elements =
    Object.keys(cartData).length === 0
      ? null
      : Object.keys(cartData)
        /* ранее использовал реверс, чтобы последние добавленные в объект пиццы, становились первыми в нем
        .reverse() */
        .map((item) => {
          const { name, bortName, price, img, id, deleted, extra, polo, kind, volume, availability } =
            cartData[item][0];
          return (
            <AsideCartItem
              key={id}
              name={name}
              bortName={bortName}
              price={price}
              img={img}
              id={id}
              deleted={deleted}
              extra={extra}
              polo={polo}
              kind={kind}
              volume={volume}
              availability={availability}
            />
          );
        });

  //ниже проверка - если произошла ошибка в фетчинге, то вернет пустоту (ведь с этими сущностями мы произодим проверки)
  if (
    loadingIngr !== "ok"
  ) {
    return null;
  }

  return (
    <aside ref={nonClickRef} className="aside-cart" style={{ top: `${scrollTopForAside ? scrollTopForAside + 'px' : ''}` }}>
      {/* показываем "оформить заказ в зависимости от страницы" */}
      <div>
        <img src={CartPng} className="aside-cart__do-order">
        </img>
      </div>
      <div className="aside-cart__header">
        <div className="aside-cart__plusCount">Кошик ({totalCountCart})</div>
        <div
          onClick={() => {
            dispath(resetAllCart());
          }}
          className="aside-cart__reset"
        >
          очистити ✖
        </div>
      </div>


      <div className={'aside-cart__wrapper-for-item ' + (path === '/order' ? 'aside-cart__wrapper-for-item--order' : '')}>{elements}</div>

      {path !== "/order" ?
        <div
          style={activePromocode ? { 'textDecoration': "line-through" } : null}
          className="aside-cart__total">
          Загальна сума: <span>{totalPriceCart} грн</span>
        </div>
        : null}



      {path !== "/order" ?
        <PromoBlock />
        : null}

      {path !== "/order" ? (
        //еще одна проверка вложенная, чтобы на главной странице нельзя было оформить заказ, если корзина пуста...
        totalCountCart === 0 ? null : (
          <Link className="aside-cart__do-order-bottom" to="/order">
            Оформити замовлення
          </Link>
        )
      ) : null}
    </aside>
  );
};

export default AsideCart;

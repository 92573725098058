import "./public-offer.scss";

import { useEffect } from "react";

const PublicOffer = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="public-offer">
      <div className="container">
        <h1 className="public-offer__title">Публічна оферта</h1>
        <div className="public-offer__text">
          Цей договір є офіційною та публічною пропозицією Продавця укласти договір купівлі-продажу Товару, представленого на сайті www.m2t.pizza.
          <br />Даний договір є публічним, тобто відповідно до статті 633 Цивільного кодексу України, його умови є однаковими для всіх покупців незалежно від їх статусу (фізична особа, юридична особа, фізична особа-підприємець) без надання переваги одному покупцю перед іншим.
          <br />Шляхом укладення цього Договору покупець в повному обсязі приймає умови та порядок оформлення замовлення, оплати товару, доставки товару, повернення товару, відповідальності за недобросовісне замовлення та усі інші умови договору.
          <br />Договір вважається укладеним з моменту натискання кнопки «Зробити замовлення без оплати» на сторінці оформлення замовлення і отримання Покупцем від Продавця підтвердження замовлення в електронному вигляді – у разі оформлення замовлення без оплати, або з моменту підтвердження перерахування коштів за товар Продавцеві на сторінці сервісу LiqPay, чому передувало натискання кнопки «Зробити замовлення з оплатою» на сторінці оформлення замовлення.
          <br />
          <br />
          1. Визначення термінів
          <br />
          1.1. Публічна оферта (далі - «Оферта») - публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем договір купівлі-продажу товару дистанційним способом (далі - «Договір») на умовах, що містяться в цій Оферті.
          <br />
          1.2. Товар або Послуга – об'єкт угоди сторін, який був обраний покупцем на сайті Інтернет-магазину та поміщений у кошик, або вже придбаний Покупцем у Продавця дистанційним способом.
          <br />
          1.3. Інтернет-магазин – сайт Продавця за адресою www.m2t.pizza створений для укладення договорів роздрібної купівлі-продажу на підставі ознайомлення Покупця із запропонованим Продавцем описом Товару за допомогою мережі Інтернет.
          <br />
          1.4. Покупець – фізична особа, яка має відповідну дієздатність, отримує інформацію від Продавця, розміщує замовлення щодо купівлі товару, що представлений на сайті Інтернет-магазину для цілей, що не пов'язані зі здійсненням підприємницької діяльності, або юридична особа чи фізична особа-підприємець.
          <br />
          1.5. Продавець – суб’єкт господарювання, що діє на підставі відповідної державної реєстрації, який реалізує товар, представлений в інтернет-магазині www.m2t.pizza.
          <br />
          <br />
          2. Предмет Договору
          <br />
          2.1. Продавець зобов’язується передати у власність Покупцю Товар, а Покупець зобов’язується оплатити і прийняти Товар на умовах цього Договору.
          <br />
          2.2. Датою укладення Договору-оферти (акцептом оферти) та моментом повного й беззаперечного прийняттям Покупцем умов Договору вважається дата заповнення Покупцем форми замовлення, розташованої на сайті Інтернет-магазину, за умови отримання Покупцем від Продавця підтвердження замовлення в електронному вигляді.
          <br />
          <br />
          3. Оформлення Замовлення
          <br />
          3.1. Покупець самостійно оформлює замовлення в Інтернет-магазині через спеціальну форму, або зробивши замовлення за номером телефону, вказаним в розділі контактів Інтернет-магазину.
          <br />
          3.2. Продавець має право відмовитися від передання замовлення Покупцеві у випадку, якщо відомості, вказані Покупцем під час оформлення замовлення, є неповними або викликають підозру щодо їх дійсності.
          <br />
          3.3. При оформленні замовлення на сайті Інтернет-магазину Покупець зобов'язується надати наступну обов’язкову  інформацію, необхідну Продавцю для виконання замовлення:
          <br />
          3.3.1. ім'я Покупця;
          <br />
          3.3.2. адреса, за якою слід доставити Товар (якщо доставка до адреси Покупця);
          <br />
          3.3.3. контактний телефон.
          <br />
          3.4. Найменування, кількість, ціна обраного Покупцем Товару вказуються в кошику Покупця на сайті Інтернет-магазину.
          <br />
          3.5. Якщо будь-якої із Сторін договору необхідна додаткова інформація, він має право запросити її у іншій Стороні. У разі ненадання необхідної інформації Покупцем, Продавець не несе відповідальності за надання якісної послуги Покупцю при покупці товару в інтернет-магазині.
          <br />
          3.6. При оформленні замовлення через оператора Продавця (п. 3.1. Цієї Оферти) Покупець зобов'язується надати інформацію, зазначену в п. 3.3 – 3.4. цієї Оферти.
          <br />
          3.7. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних в реєстраційну форму на сайті Інтернет-магазину або при оформленні Замовлення через оператора. Після оформлення Замовлення через Оператора дані про Покупця вносяться до бази даних Продавця.
          <br />
          3.8. Покупець несе відповідальність за достовірність наданої інформації при оформленні Замовлення.
          <br />
          3.9. Укладаючи Договір, тобто акцептуючи умови даної пропозиції (запропоновані умови придбання Товару), шляхом оформлення Замовлення, Покупець підтверджує наступне:
          <br />
          а) Покупець цілком і повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);
          <br />
          б) він дає дозвіл на збір, обробку та передачу персональних даних, дозвіл на обробку персональних даних діє протягом усього терміну дії Договору, а також протягом необмеженого терміну після закінчення його дії. Крім цього, укладенням договору Покупець підтверджує, що він повідомлений (без додаткового повідомлення) про права, встановлених Законом України "Про захист персональних даних", про цілі збору даних, а також про те, що його персональні дані передаються Продавцю з метою можливості виконання умов цього Договору, можливості проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів. Покупець також погоджується з тим, що Продавець має право надавати доступ та передавати його персональні дані третім особам без будь-яких додаткових повідомлень Покупця з метою виконання замовлення Покупця. Обсяг прав Покупця, як суб'єкта персональних даних відповідно до Закону України "Про захист персональних даних" йому відомий і зрозумілий.
          <br />
          <br />
          4. Ціна і Доставка Товару
          <br />
          4.1 Ціни на Товари та послуги визначаються Продавцем самостійно та вказані на сайті Інтернет-магазину. Всі ціни на Товари та послуги вказані на сайті у гривнях.
          <br />
          4.2 Ціни на Товари та послуги можуть змінюватися Продавцем в односторонньому порядку залежно від кон'юнктури ринку. При цьому ціна окремої одиниці Товару, вартість якої оплачена Покупцем в повному обсязі, не може бути змінена Продавцем в односторонньому порядку.
          <br />
          4.3. Вартість окремого Товару, яка вказана на сайті Інтернет-магазину не включає в себе вартість доставки Товару Покупцю. Разом з тим, загальна вартість замовлення, яка відображається на сторінці оформлення замовлення, може включати й вартість доставки, якщо покупець робить замовлення з доставкою.
          <br />
          4.6. Зобов'язання Покупця по оплаті Товару вважаються виконаними з моменту надходження Продавцю коштів на його рахунок.
          <br />
          4.7. Розрахунки між Продавцем і Покупцем за Товар здійснюються способами, зазначеними на сайті Інтернет-магазину в розділі «Оплата і Доставка».
          <br />
          4.8. Право власності та ризик випадкової втрати або пошкодження Товару переходить до Покупця або його Представника з моменту отримання Товару Покупцем.
          <br />
          <br />
          5. Права та обов’язки Сторін
          <br />
          5.1. Продавець зобов’язаний:
          <br />
          5.1.1. Передати Покупцеві товар у відповідності до умов цього Договору та замовлення Покупця.
          <br />
          5.1.2. Не розголошувати будь-яку приватну інформацію про Покупця і не надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених законодавством та під час виконання Замовлення Покупця.
          <br />
          5.2. Продавець має право:
          <br />
          5.2.1 Змінювати умови цього Договору, а також ціни на Товари та послуги, в односторонньому порядку, розміщуючи їх на сайті Інтернет-магазину. Всі зміни набувають чинності з моменту їх публікації.
          <br />
          5.3 Покупець зобов'язується:
          <br />
          5.3.1 До моменту укладення Договору ознайомитися зі змістом Договору, умовами Договору і цінами, запропонованими Продавцем на сайті Інтернет-магазину.
          <br />
          5.3.2 На виконання Продавцем своїх зобов'язань перед Покупцем останній повинен повідомити всі необхідні дані, що однозначно ідентифікують його як Покупця, і достатні для доставки Покупцеві замовленого Товару.
          <br />
          <br />
          6. Відповідальність
          <br />
          6.1. Продавець і Покупець несуть відповідальність за виконання своїх зобов'язань відповідно до чинного законодавства України і положень цього Договору.
          <br />
          6.2. Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов'язань, якщо невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця і / або Покупця після укладення цього договору. Сторона, яка не може виконати свої зобов'язання, негайно повідомляє про це іншу Сторону.
          <br />
          <br />
          7. Конфіденційність і захист персональних даних.
          <br />
          7.1. Надаючи свої персональні дані на сайті Інтернет-магазину при реєстрації або оформленні Замовлення, Покупець надає Продавцеві свою добровільну згоду на обробку, використання (у тому числі і передачу) своїх персональних даних, а також вчинення інших дій, передбачених Законом України «Про захист персональних даних», без обмеження терміну дії такої згоди.
          <br />
          7.2. Продавець зобов'язується не розголошувати отриману від Покупця інформацію. Не вважається порушенням надання Продавцем інформації контрагентам і третім особам, що діють на підставі договору з Продавцем, в тому числі і для виконання зобов'язань перед Покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.
          <br />
          7.3. Покупець несе відповідальність за підтримання своїх персональних даних в актуальному стані. Продавець не несе відповідальності за неякісне виконання або невиконання своїх зобов'язань у зв'язку з неактуальністю інформації про Покупця або невідповідністю її дійсності.
          <br />
          <br />
          8. Інші умови
          <br />
          8.1. Цей договір укладено на території України і діє відповідно до чинного законодавства України.
          <br />
          8.2. Усі спори, що виникають між Покупцем і Продавцем, вирішуються шляхом переговорів. У випадку недосягнення врегулювання спірного питання шляхом переговорів, Покупець та/або Продавець мають право звернутися за вирішенням спору до судових органів відповідно до чинного законодавства України.
          <br />
          8.3. Продавець має право вносити зміни до цього Договору в односторонньому порядку, передбаченому п. 5.2.1. Договору. Крім того, зміни до Договору також можуть бути внесені за взаємною згодою Сторін в порядку, передбаченому чинним законодавством України.



        </div>

      </div>
    </section >
  );
};

export default PublicOffer;

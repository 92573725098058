const AsideCartItemPolo = ({ deleted, ingr, name, extra, img, price, cartData, id, bortName, deletePositionCart, minusToCart, plusToCart, dispatch }) => {

  const { deletedIngrForOne, deletedIngrForTwo } = deleted;
  const deletedOnePolo = Object.keys(deletedIngrForOne).map((item) => {
    return <li key={item}>-{ingr[item][0]}</li>;
  });
  const deletedSecondPolo = Object.keys(deletedIngrForTwo).map((item) => {
    return <li key={item}>-{ingr[item][0]}</li>;
  });

  const { namePoloOne, namePoloTwo } = name;

  const { extraIngr, extraIngrFirst, extraIngrSecond } = extra;
  const extraIngred = Object.keys(extraIngr).map((item, index) => {
    return (
      <li key={item + index + 1}>
        +{ingr[item][0]} ({extraIngr[item].length})
      </li>
    );
  });
  const extraIngredFirst = Object.keys(extraIngrFirst).map((item, index) => {
    return (
      <li key={item + index + 1}>
        +{ingr[item][0]} ({extraIngrFirst[item].length})
      </li>
    );
  });
  const extraIngredSecond = Object.keys(extraIngrSecond).map(
    (item, index) => {
      return (
        <li key={item + index + 1}>
          +{ingr[item][0]} ({extraIngrSecond[item].length})
        </li>
      );
    }
  );

  const { firstPoloImg, secondPoloImg } = img;
  const thisPizzaTotalPricePolos = price * cartData[id].length;

  return (
    <>
      <div className="aside-cart__item">
        <div className="aside-cart__body">
          <div className="flex">
            <div className="aside-cart__body-pizza-img-wrap">
              <img
                src={firstPoloImg}
                alt="1polo"
                className="aside-cart__body-pizza-img-wrap-polo"
              />
              <img
                src={secondPoloImg}
                alt="2polo"
                className="aside-cart__body-pizza-img-wrap-polo"
              />
            </div>

            <div>
              <div className="aside-cart__pizparam-wrap">
                <div className="aside-cart__body-pizza-title title-polo">
                  {namePoloOne}
                </div>
                <ul className="aside-cart__body-extra">{extraIngredFirst}</ul>
                <ul className="aside-cart__body-deleteIngr">
                  {deletedOnePolo}
                </ul>
              </div>

              <div className="aside-cart__pizparam-wrap">
                <div className="aside-cart__body-pizza-title title-polo">
                  {namePoloTwo}
                </div>

                <ul className="aside-cart__body-extra">
                  {extraIngredSecond}
                </ul>
                <ul className="aside-cart__body-deleteIngr">
                  {deletedSecondPolo}
                </ul>
              </div>
              <div className="aside-cart__body-params aside-cart__body-params_halfs-mod">
                {bortName} борт
              </div>
              {extraIngred.length !== 0 ? (
                <ul className="aside-cart__body-extra aside-cart__body-extra_halfs-mod">
                  Додано до обох половинок:<br></br>
                  {extraIngred}
                </ul>
              ) : null}
            </div>
          </div>

          <button
            onClick={() => {
              dispatch(deletePositionCart(id));
            }}
            className="aside-cart__body-delete"
          >
            ✖
          </button>
        </div>
        <div className="aside-cart__footer">
          <div className="aside-cart__footer-config-count">
            <button
              onClick={() => {
                dispatch(minusToCart(id));
              }}
              className="aside-cart__min btn-minus"
            >
              -
            </button>
            <span className="aside-cart__count">
              {cartData[id] ? cartData[id].length : 0}
            </span>
            <button
              onClick={() => {
                dispatch(plusToCart(id));
              }}
              className="aside-cart__plus btn-plus"
            >
              +
            </button>
          </div>
          <div className="aside-cart__price">
            {thisPizzaTotalPricePolos} грн
          </div>
        </div>
      </div>
    </>
  );
}

export default AsideCartItemPolo;

import ListOfAddIngrWithDeleteFunc from "../../mod-pizza/add-ingredients/ListOfAddIngrWithDeleteFunc";
import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";

import { useOutletContext, Link } from "react-router-dom";


export default function MExportsPizza() {

  const { navigate, img, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, ingr, name, modPrice, countModPizza, setCountModPizza, fullId } = useOutletContext();
  return (
    <div className="m-modal">
      <div className="m-modal__content">
        {/* ниже убираем крестик, если активно окно добавления ингредиентов */}

        <div
          onClick={() => {
            navigate("/");
          }}
          className="m-modal__close"
        >
          ╳
        </div>


        <div className="m-modal-wr">
          <img src={img} alt="#" className="m-modal__img" />
          <div className="m-modal__wrapper">

            <>
              <div className="m-modal__title-weight-wr">
                <h2 className="m-modal__pizza-name">{name}</h2>
                <span className="m-modal__weight">
                  30 см
                </span>
              </div>


              <div className="m-authors-pizza__advice">
                Початковий набір інгредієнтів складається з двох порцій сирів (Моцарела,&nbsp;Гауда), а також червоного соусу.
                <br></br>
                Ви можете змінити ці інгредієнти та додати інші.
              </div>

              <div className="m-authors-pizza__wrapper-for-adding">
                {Object.keys(extraIngr).length ? (
                  <>
                    <div className="m-addIngredients-text__inner m-addIngredients-text__inner--m-authors-pizza">
                      Інгредієнти вашої піци:
                    </div>
                    <ul className="modal__pizza-extra m-modal__pizza-extra m-modal__pizza-extra--m-autors-pizza">
                      <ListOfAddIngrWithDeleteFunc
                        ingr={ingr}
                        extraIngr={extraIngr}
                        setExtraIngr={setExtraIngr}
                      />
                    </ul>
                  </>
                ) : <div className="m-addIngredients-text__inner m-addIngredients-text__inner--m-authors-pizza">
                  Ваша піца не містить інгредієнтів, окрім тіста. Додайте щось.
                </div>}

                <Link to="adding">
                  <button
                    className="m-addIngredients-text m-addIngredients-text--m-authors-pizza"
                  >
                    Додати (змінити) інгредієнти
                  </button>
                </Link>
              </div>

              <div className="m-modal__borts-i">Обрати бортик ↓</div>
              <div className="m-modal__borts">
                {Object.keys(borts).map((item) => {
                  return (
                    <span
                      className={
                        item === bortIndex
                          ? "m-modal__borts-item m-modal__borts-item-act"
                          : "m-modal__borts-item"
                      }
                      key={1 + item}
                      onClick={() => {
                        setBortIndex(item);
                      }}
                    >
                      {borts[item].title}
                    </span>
                  );
                })}
              </div>
              <div className="m-modal__action-wr">
                <BlockWithPriceCountCart
                  modPrice={modPrice}
                  countModPizza={countModPizza}
                  setCountModPizza={setCountModPizza}
                  fullId={fullId}
                  borts={borts}
                  bortIndex={bortIndex}
                  name={name}
                  img={img}
                  extraIngr={extraIngr}
                />
              </div>
            </>

          </div>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToCart, minusToCart, plusToCart } from '../../features/cartSlice';

import ChooseVarietiesForDrinks from './ChooseVarietiesForDrinks';
import ImgWithPreloadSpinner from '../little/ImgWithPreloadSpinner';

import useMedia768 from '../../hooks/useMedia';


const DrinksItem = ({ info }) => {
  const isMobile = useMedia768();
  const cartData = useSelector(state => state.cart.cart);
  const dispatch = useDispatch();
  const { id, kind, img, name, descr, varieties, default_varieties, availability } = info;
  const btnZone = () => {
    if (!availability) {
      return <span>немає в наявності</span>
    }
    else if (!cartData[fullId]) {
      return (
        <button
          onClick={() => {
            dispatch(
              addToCart({
                id: fullId,
                price: varieties[currentVarietiesIndex].price,
                name,
                img,
                kind,
                volume: varieties[currentVarietiesIndex].volume,
                availability
              })
            );
          }}
          className="pizzablock__addCart-btn"
        >
          У кошик
        </button>
      )
    }
    else {
      return (
        <div>
          {isMobile ? '✔' : 'У кошику'}&nbsp;&nbsp;
          <button
            className="btn-minus"
            onClick={() => {
              dispatch(minusToCart(fullId));
            }}
          >
            -
          </button>
          {/* без проверки работать не будет. Мы отрисовывем количество пицц под данным АЙДИ. */}
          <span>{cartData[fullId] ? cartData[fullId].length : 0}</span>
          <button
            className="btn-plus"
            onClick={() => {
              dispatch(plusToCart(fullId));
            }}
          >
            +
          </button>
        </div>
      )
    }
  }


  const [currentVarietiesIndex, setCurrentVarietiesIndex] = useState(default_varieties);
  const fullId = id + varieties[currentVarietiesIndex].volume;

  if (isMobile) {
    return (
      <li className="mmi">
        <div className="mmi__img-wr">
          <ImgWithPreloadSpinner alt={name + '_pic'} src={img} className={'mmi__img'} />
        </div>
        <div className="mmi__sec-wr">
          <div>
            <div className="mmi-pname-pweight-wr">
              <h2 className="mmi__pname">{name} ({varieties[currentVarietiesIndex].volume}&nbsp;л)</h2>

            </div>
            <div className="mmi__pdescr">{descr}</div>
          </div>
          <ChooseVarietiesForDrinks
            varieties={varieties}
            currentVarietiesIndex={currentVarietiesIndex}
            setCurrentVarietiesIndex={setCurrentVarietiesIndex}
          />
          <div className="mmi__info-wr">
            <span className="mmi__price">{varieties[currentVarietiesIndex].price} грн</span>
            {btnZone()}
          </div>
        </div>
      </li>
    )
  }

  return (
    <li className="drinkblock__item">
      <ImgWithPreloadSpinner alt={name + '_pic'} src={img} className={'pizzablock__pizza-img'} />
      <h3 className="drinkblock__item-title">{name} ({varieties[currentVarietiesIndex].volume}&nbsp;л)</h3>
      <div className="drinkblock__item-descr">{descr}</div>


      <ChooseVarietiesForDrinks
        varieties={varieties}
        currentVarietiesIndex={currentVarietiesIndex}
        setCurrentVarietiesIndex={setCurrentVarietiesIndex}
      />

      <div className="pizzablock__order-details">
        <span className="pizzablock__price">
          {varieties[currentVarietiesIndex].price} грн <span style={{ fontSize: "10px" }}>(за од.)</span>
        </span>
        {btnZone()}
      </div>
    </li>
  )
}


export default DrinksItem;

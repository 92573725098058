import { useOutletContext, useNavigate, Link } from 'react-router-dom';

import BlockWithPriceCountCart from "../../little/BlockWithPriceCountCart";
import DescrElemWithDeleteFunc from "../delete-ingredients/DescrElemWithDeleteFunc";
import ListOfAddIngrWithDeleteFunc from "../add-ingredients/ListOfAddIngrWithDeleteFunc";



export default function MModPizza() {
  const { img, name, weight, isNew, isHit, isHot, isVegan, deletedIngr, setDeletedIngr, descr, ingr, borts, bortIndex, setBortIndex, extraIngr, setExtraIngr, modPrice, countModPizza, setCountModPizza, fullId } = useOutletContext();

  const navigate = useNavigate();
  return (
    /* ниже версия для мобилок */
    <div className="m-modal">
      <div className="m-modal__content">

        <div
          onClick={() => {
            navigate("/");
          }}
          className="m-modal__close"
        >
          ╳
        </div>


        <div className="m-modal-wr">
          <img src={img} alt="#" className="m-modal__img" />
          <div className="m-modal__wrapper">
            <div className="m-modal__title-weight-wr">
              <h2 className="m-modal__pizza-name">{name}</h2>
              <span className="m-modal__weight">
                {weight} | 30 см
              </span>{" "}
            </div>
            <div className="m-modal__specials">
              {isNew} {isHit} {isVegan} {isHot}
            </div>
            <ul className="modal__pizza-descr m-modal__pizza-descr">
              <DescrElemWithDeleteFunc
                deletedIngr={deletedIngr}
                setDeletedIngr={setDeletedIngr}
                descr={descr}
                ingr={ingr}
              />{" "}
            </ul>
            <div className="m-modal__borts-i">Обрати бортик ↓</div>
            <div className="m-modal__borts">
              {Object.keys(borts).map((item) => {
                return (
                  <span
                    className={
                      item === bortIndex
                        ? "m-modal__borts-item m-modal__borts-item-act"
                        : "m-modal__borts-item"
                    }
                    key={1 + item}
                    onClick={() => {
                      setBortIndex(item);
                    }}
                  >
                    {borts[item].title}
                  </span>
                );
              })}
            </div>

            <Link to={'add-to-pizza'}
              className="m-addIngredients-text"
            >
              Додати ще інгредієнтів
            </Link>
            {Object.keys(extraIngr).length ? (
              <>
                <div className="m-addIngredients-text__inner">
                  Ви додали до піци:
                </div>
                <ul className="modal__pizza-extra m-modal__pizza-extra">
                  <ListOfAddIngrWithDeleteFunc
                    ingr={ingr}
                    extraIngr={extraIngr}
                    setExtraIngr={setExtraIngr}
                  />
                </ul>
              </>
            ) : null}
            <div className="m-modal__action-wr">
              <BlockWithPriceCountCart
                modPrice={modPrice}
                countModPizza={countModPizza}
                setCountModPizza={setCountModPizza}
                fullId={fullId}
                borts={borts}
                bortIndex={bortIndex}
                deletedIngr={deletedIngr}
                name={name}
                img={img}
                extraIngr={extraIngr}
              />
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

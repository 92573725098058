import { useDispatch } from "react-redux";

import { addToCart } from "../../features/cartSlice";

import { useNavigate } from "react-router-dom";

const BlockWithPriceCountCart = ({
  modPrice,
  countModPizza,
  setCountModPizza,
  fullId,
  borts,
  bortIndex,
  deletedIngr,
  name,
  img,
  extraIngr,
  polo,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <span className="modal__pizza-price">{modPrice} грн</span>
      <div className="pizzablock__countOfPizza">
        <button
          className="btn-minus"
          onClick={() => {
            countModPizza <= 1
              ? setCountModPizza(1)
              : setCountModPizza(countModPizza - 1);
          }}
        >
          -
        </button>
        <span>{countModPizza}</span>
        <button
          className="btn-plus"
          onClick={() => {
            setCountModPizza(countModPizza + 1);
          }}
        >
          +
        </button>
      </div>
      <button
        //записываем в редакс-стэйт корзины все данные. Обработанный айди и удаленные ингредиенты, которые сперва преобразуем в массив для более удобного в дальнейшем использования. Напомню, что в этом массиве хранятся порядковые номера.
        //используется цикл для добавления пицц в корзину в соответствии с выбранным пользователем количеством
        onClick={() => {
          for (let i = 1; i <= countModPizza; i++) {
            //ниже в ЦЕНЕ я делю общую цену, которую получил для отображение в этом компоненте, на количество выбранных пицц, чтобы передать в корзину цену как за одну пиццу - чтобы в корзине уже нормально работало увеличение и уменьшение количества
            dispatch(
              addToCart({
                polo,
                id: fullId,
                price: modPrice / countModPizza,
                name,
                bortName: borts[bortIndex].title,
                img,
                deleted: { ...deletedIngr },
                extra: { ...extraIngr },
              })
            );
          }
          //возвращаемся на главную
          navigate("/");
        }}
        className="pizzablock__addCart-btn"
      >
        У кошик
      </button>
    </>
  );
};

export default BlockWithPriceCountCart;

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetAllCart, deleteActivePromoCode } from "../../../features/cartSlice";

/* import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { setHours, setMinutes }from 'date-fns';
import uk from 'date-fns/locale/uk';
import "react-datepicker/dist/react-datepicker.css"; */
import PhoneInput from "react-phone-input-2";
import ButtonPay from "./ButtonPay";
import Zones from "./ass/Zones";
import PromoBlock from "../../little/promoBlock/PromoBlock";


import { useSelector } from "react-redux";

/* import {Day, Time} from './DataPickers' */

const city = {
  0: { city: "Добропілля", price: "60-85" },
  1: { city: "Ганнівка", price: "140" },
  /*  2: { city: "Святогорівка", price: "200" }, */
};
const zones = { a: ["Центральний", 60], b: ["Околиця", 85] };
const DeliveryForm = ({ type, setOrderSuccess, canPay }) => {
  const dispatch = useDispatch();

  const [namer, setNamer] = useState(localStorage.getItem("namer") || "");
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [street, setStreet] = useState(localStorage.getItem("street") || "");
  const [house, setHouse] = useState(localStorage.getItem("house") || "");
  const [aprts, setAprts] = useState(localStorage.getItem("aprts") || "");
  const [floor, setFloor] = useState("");
  const [entrance, setEntrance] = useState("");
  const [code, setCode] = useState("");
  const [comments, setComments] = useState("");
  const [customDate, setCustomDate] = useState("");
  const [chosenCity, setChosenCity] = useState(
    localStorage.getItem("city") || "0"
  );
  const [chosenZone, setChosenZone] = useState(
    localStorage.getItem("zone") || "a"
  );
  const [specialPromoPrice, setSpecialPromoPrice] = useState(null);
  const activePromocode = useSelector(state => state.cart.activePromocode);
  //получим конечную стоимость для отображения перед кнопкой заказа
  const totalPrice = useSelector((state) => state.cart.totalPriceCart);

  useEffect(() => {

    if (activePromocode?.formating === 'percent') {
      const techno = +((totalPricePlusDelivery * activePromocode.percent_to_math).toFixed(1));
      setSpecialPromoPrice(techno)
    }
    if (activePromocode?.formating === 'cash') {
      const techno = +((totalPricePlusDelivery - activePromocode.cash).toFixed(1));
      setSpecialPromoPrice(techno < 0 ? 0 : techno)
    }
  }, [activePromocode?.formating, chosenZone, chosenCity, totalPrice])



  //если эсФаст тру, значит доставка нужна как можно быстрее, иначе - на конкретное время и дату - появляется соответствующие инпуты (опять же условный рендеринг)
  const [isAsFast, setIsAsFast] = useState(true);

  //общая сумма заказа с доставкой
  const totalPricePlusDelivery =
    totalPrice +
    +(chosenCity === "0" ? zones[chosenZone][1] : city[chosenCity].price);

  const [isZone, setIsZone] = useState(false);

  //ниже решение такое себе...
  //ниже таймЮА - по умолчанию сделал его как время открытия заведения. Здесь у нас создается новый экземпляр объекта ДАТА с кастомными часами и минутами.
  //формат такой: Sun Sep 04 2022 15:45:00 GMT+0300 (Восточная Европа, летнее время)
  //такое поведение понадобится в компонентах РЕАКТ-ДАТАПИКЕРА
  /*  const [timeUa, setTimeUa] = useState(setHours(setMinutes(new Date(), 0), 10)) */

  //ниже при монтировании компонента я делаю запрос, чтобы получить время по Киеву.
  //получаю объект в ключе DATETIME которого хранится значение: '2022-09-04T13:59:56.063025+03:00'
  //однако РЕАКТ-ДАТАПИКЕР работает, только с таким форматом Sun Sep 04 2022 15:45:00 GMT+0300 (Восточная Европа, летнее время)
  //поєтому мы снова обращаемся к конструтору ДАТЫ и записываем необходимое значение в СТЭЙТ. Теперь изначально указанное время- текущее время.
  //записіваю в СТЭЙТ этого компонента, чтобы затем ПРОПСАМИ передать его в два компонента РЕАКТ-ДАТАПИКЕРА (ТАЙМ и ДЭЙТ)
  /*    useEffect(()=>{
        fetch('http://worldtimeapi.org/api/timezone/Europe/Kiev')
        .then(res=>res.json())
        .then(res=>setTimeUa(new Date(res.datetime)));

        //ниже при монтировании компонента зарегистрировал украинскую локализацию для РЕАКТ-ДАТАПИКЕРА
        registerLocale('uk', uk)
        setDefaultLocale('uk')
    }, [isAsFast])
    //выше решение такое себе... */

  //clarify - уточнение - условній рендеринг для отображения инпутов с подъездом, этажом (опциция)
  const [clarify, setClarify] = useState(false);

  const [chooseCityPopUp, setChooseCityPopUp] = useState(false);

  const handleClick = () => {
    setChooseCityPopUp(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [setChooseCityPopUp]);




  //далее функционал тоже для условного рендеринга, платить после доставки или онлайн
  const [payAfter, setPayAfter] = useState(true);
  //далее функционад для понимания - нужно ли перезванивать
  const [wishCall, setWishCall] = useState(true);

  //надо же отправлять данные из корзины...
  const cartData = useSelector((state) => state.cart.cart);
  const ingr = useSelector((state) => state.general.ingr);
  const loadingIngr = useSelector((state) => state.general.loadingIngr);

  if (loadingIngr !== "ok") {
    return null;
  }
  const arrOfPizzas = Object.values(cartData).map((item) => {
    const { polo, kind } = item[0];

    if (polo) {
      const { extraIngr, extraIngrFirst, extraIngrSecond } = item[0].extra;
      const { deletedIngrForOne, deletedIngrForTwo } = item[0].deleted;
      const { namePoloOne, namePoloTwo } = item[0].name;

      const deletedFromOne = Object.keys(deletedIngrForOne).map(
        (item) => `(${ingr[item][0]})`
      );
      const deletedFromTwo = Object.keys(deletedIngrForTwo).map(
        (item) => `(${ingr[item][0]})`
      );

      const extraAll = Object.keys(extraIngr).map((item) => {
        return `(${ingr[item][0]} - x${extraIngr[item].length})`;
      });
      const extraFirst = Object.keys(extraIngrFirst).map((item) => {
        return `(${ingr[item][0]} - x${extraIngrFirst[item].length})`;
      });
      const extraSecond = Object.keys(extraIngrSecond).map((item) => {
        return `(${ingr[item][0]} - x${extraIngrSecond[item].length})`;
      });

      return `\n\n➤ <b>х${item.length
        } - ${namePoloOne.toUpperCase()}+${namePoloTwo.toUpperCase()}</b> - ${item[0].price * item.length
        } грн ||| <b>борт ${item[0].bortName}</b> ${deletedFromOne.length !== 0
          ? `||| <b>видалено з ${namePoloOne}:</b> ${deletedFromOne}`
          : ``
        } ${deletedFromTwo.length !== 0
          ? `||| <b>видалено з ${namePoloTwo}:</b> ${deletedFromTwo}`
          : ``
        } ${extraAll.length !== 0
          ? `||| <b>додано до всієї піци:</b> ${extraAll}`
          : ``
        } ${extraFirst.length !== 0
          ? `||| <b>додано до ${namePoloOne}:</b> ${extraFirst}`
          : ``
        } ${extraSecond.length !== 0
          ? `||| <b>додано до ${namePoloTwo}:</b> ${extraSecond}`
          : ``
        }`;
    }
    else if (kind === 'drinks') {
      return `\n\n➤ <b>х${item.length} - ${item[0].name.toUpperCase()}</b> <b>(${item[0].volume} л)</b> - ${item[0].price * item.length} грн`;
    }
    else {
      const { extra } = item[0];

      const deleted = Object.keys(item[0].deleted).map(
        (item) => `(${ingr[item][0]})`
      );
      const extras = Object.keys(extra).map((item) => {
        return `(${ingr[item][0]} - x${extra[item].length})`;
      });
      return `\n\n➤ <b>х${item.length} - ${item[0].name.toUpperCase()}</b> - ${item[0].price * item.length
        } грн ||| <b>борт ${item[0].bortName}</b> ${deleted.length !== 0 ? `||| <b>видалено з піци:</b> ${deleted}` : ``
        } ${extras.length !== 0 ? `||| <b>додано до піци:</b> ${extras}` : ``}`;
    }
  });

  const downSymbol = clarify ? "▲" : "▼";
  const downSymbol2 = chooseCityPopUp ? "▲" : "▼";

  const chooseElemTown = Object.keys(city).map((item) => {
    return (
      <li
        className="delivery__city-li"
        onClick={() => {
          setChosenCity(item);
          setChooseCityPopUp(false);
        }}
      >
        {city[item].city}
      </li>
    );
  });

  const messageToTgWithPromo = `${activePromocode?.title ? `\n\n⚠️ Увага! Задіяно промо-код "${activePromocode.title}", який дає скидку ${activePromocode.percent ? activePromocode.percent : `${activePromocode.cash} грн.`} Остаточна ціна ${specialPromoPrice} грн.` : ""}`;

  const fetchToUpdateUsedPromo = async () => {
    if (activePromocode?.title) {
      await fetch('http://localhost:5001/update-used', {
        method: 'POST',
        body: JSON.stringify({ title: activePromocode.title })
      })
        .then(res => {
          if (!res.ok) {
            console.log('problem with fetch update-used')
          }
        })
        .catch(err => console.log(err));

      document.cookie = 'promoTitle=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    }
  }



  return (
    <form className="delivery" action="">
      {isZone ? (
        <Zones
          zones={zones}
          chosenZone={chosenZone}
          setChosenZone={setChosenZone}
          setIsZone={setIsZone}
        />
      ) : null}
      <fieldset className="delivery__contact-fieldset">
        <legend className="delivery__contact-legend">
          Контактна інформація
        </legend>

        <div>
          <label className="delivery-label-star" htmlFor="namer">
            ім'я
          </label>
          <input
            className="delivery__contact-name"
            id="namer"
            name="namer"
            type="text"
            value={namer}
            onChange={(e) => {
              setNamer(e.target.value);
            }}
          />
        </div>

        <div className="delivery__contact-phone-wrapper">
          <label className="delivery-label-star">тел.</label>
          <PhoneInput
            inputClass={"delivery__contact-phone"}
            placeholder={"Телефон"}
            disableDropdown={true}
            country={"ua"}
            countryCodeEditable={false}
            onlyCountries={["ua"]}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
        </div>

        {/*  <input
             onChange={(e)=>{setPhone(e.target.value)}}
            className="delivery__contact-phone" name="phone" type="text" placeholder="Телефон"value={phone} /> */}
      </fieldset>

      <fieldset className="delivery__address-fieldset">
        <legend className="delivery__address-legend">Адреса</legend>
        <span
          onClick={(e) => {
            e.stopPropagation();
            setChooseCityPopUp(!chooseCityPopUp);
          }}
          className="delivery__city"
          aria-hidden="true"
        >
          {city[chosenCity].city} {downSymbol2}
        </span>
        {chooseCityPopUp ? (
          <div style={{ position: "relative" }}>
            <ul className="delivery__city-ul">{chooseElemTown}</ul>
          </div>
        ) : null}

        {chosenCity === "0" ? (
          <>
            <div
              onClick={(e) => {
                /* отменил всплытие, иначе не работало, хз почему */
                e.stopPropagation();
                setIsZone(true);
              }}
              className="delivery__zone"
            >
              <span className="delivery__zone-text">
                Район міста: {zones[chosenZone][0]}
              </span>
              <span className="delivery__choose-zone">Обрати інший район</span>
            </div>
          </>
        ) : null}

        <div className="delivery__cost">
          вартість доставки{" "}
          {chosenCity === "0"
            ? zones[chosenZone][1] + " грн"
            : city[chosenCity].price + " грн"}
        </div>

        <div className="delivery__address-first-wrapper">
          <div>
            <label className="delivery-label-star" htmlFor="street">
              вул.
            </label>
            <input
              value={street}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
              id="street"
              name="street"
              type="text"
              className="delivery__street"
            />
          </div>

          <div>
            <label className="delivery-label-star" htmlFor="house">
              буд.
            </label>
            <input
              onChange={(e) => {
                setHouse(e.target.value);
              }}
              id="house"
              value={house}
              name="house"
              type="text"
              className="delivery__house"
            />
          </div>

          <div>
            <label htmlFor="aprts">кв.</label>
            <input
              id="aprts"
              value={aprts}
              onChange={(e) => {
                setAprts(e.target.value);
              }}
              name="aprts"
              type="text"
              className="delivery__flat"
              placeholder="квартира / офіс"
            />
          </div>
        </div>

        <span
          onClick={() => {
            setClarify(!clarify);
          }}
          className="clarify"
        >
          Вказати під'їзд, код, поверх {downSymbol}
        </span>
        {clarify && (
          <div className="delivery__address-second-wrapper">
            <div>
              <label htmlFor="entrance">під'їзд</label>
              <input
                onChange={(e) => {
                  setEntrance(e.target.value);
                }}
                id="entrance"
                name="entrance"
                type="text"
                className="delivery__entrance"
              />
            </div>

            <div>
              <label htmlFor="code">код</label>
              <input
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                id="code"
                name="code"
                type="text"
                className="delivery__house"
              />
            </div>

            <div>
              <label htmlFor="floor">поверх</label>
              <input
                onChange={(e) => {
                  setFloor(e.target.value);
                }}
                id="floor"
                name="floor"
                type="text"
                className="delivery__flat"
              />
            </div>
          </div>
        )}
      </fieldset>

      <fieldset className="delivery__data-fieldset">
        <legend className="delivery__data-legend">Час та дата</legend>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setIsAsFast(true);
            }}
            className="asFast"
          >
            <span
              className={isAsFast ? "custom-radio checked" : "custom-radio"}
            ></span>
            Якомога швидше
          </div>
          <div
            onClick={() => {
              setIsAsFast(false);
            }}
            className="onTheData"
          >
            <span
              className={isAsFast ? "custom-radio" : "custom-radio checked"}
            ></span>
            На певний час / дату
          </div>
        </div>
        {isAsFast ? (
          <p className="order__time-info">
            *Ми намагатимося привезти ваше замовлення настільки швидко,
            наскільки це можливо з урахуванням актуальної черги.
          </p>
        ) : (
          /* <div style={{marginTop:'15px', display: 'flex'}}>

                 <Time timeUa={timeUa}/>
                <Day timeUa={timeUa}/>

                </div> */
          <textarea
            value={customDate}
            onChange={(e) => {
              setCustomDate(e.target.value);
            }}
            className="order__custom-date"
            name="date"
            placeholder='Наприклад: "сьогодні о 15:00..."'
          ></textarea>
        )}
      </fieldset>

      <fieldset className="delivery__pay-fieldset">
        <legend className="delivery__pay-label">Оплата</legend>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setPayAfter(true);
            }}
            className="asFast"
          >
            <span
              className={payAfter ? "custom-radio checked" : "custom-radio"}
            ></span>
            Оплата при отриманні
          </div>

          {/* ниже накинул єтот КєнПєй для отключения оплаты - временное решение */}
          {canPay === "yes" ? (
            <div
              onClick={() => {
                setPayAfter(false);
              }}
              className="onTheData"
            >
              <span
                className={payAfter ? "custom-radio" : "custom-radio checked"}
              ></span>
              Оплата online
            </div>
          ) : null}
        </div>
        {payAfter ? (
          <p className="order__pay-info">
            *Ви зможете оплатити своє замовлення безпосередньо після його
            отримання. <br></br>Наш драйвер прийме оплату готівкою або через
            безготівковий розрахунок, використовуючи банківський міні-термінал.
          </p>
        ) : null}
      </fieldset>

      <fieldset className="order__add-fieldset">
        <legend className="order__add-label">Додаткова інформація</legend>
        <p className="order__ask-for-call">
          Передзвонити для уточнення замовлення?
        </p>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setWishCall(true);
            }}
            className="asFast"
          >
            <span
              className={wishCall ? "custom-radio checked" : "custom-radio"}
            ></span>
            Так
          </div>
          <div
            onClick={() => {
              setWishCall(false);
            }}
            className="onTheData"
          >
            <span
              className={!wishCall ? "custom-radio checked" : "custom-radio"}
            ></span>
            Ні
          </div>
        </div>
        {wishCall ? null : (
          <p className="delivery-note-about-call">
            Але якщо у нас виникнуть питання, ми всеодно муситимо вам подзовнити
            :)
          </p>
        )}
        <p className="order__message-todel">Коментар до служби доставки</p>
        <textarea
          value={comments}
          onChange={(e) => {
            setComments(e.target.value);
          }}
          className="order__comments"
          name="comments"
          placeholder="залиште повідомлення у цьому текстовому полі, якщо бажаєте..."
        ></textarea>
      </fieldset>

      <div
        style={activePromocode ? { 'textDecoration': "line-through" } : null}
        className="order__form-totalPrice">
        Загальна вартість замовлення - {totalPricePlusDelivery} грн
      </div>
      <div
        className="order__form-deliveryPrice">
        *вартість доставки (
        {chosenCity === "0"
          ? zones[chosenZone][1] + " грн"
          : city[chosenCity].price + " грн"}
        ) включено
      </div>

      <PromoBlock specialPromoPrice={specialPromoPrice} />
      {/* ниже будет проверка. Если сумма нулевая, то вместо кнопки подтверждения заказа - мы будем показывать надпись */}
      {totalPrice == 0 ? (
        <div className="order__attention-empty">
          Спершу додайте товар до кошика... :)
        </div>
      ) : payAfter ? (
        <button
          onClick={(e) => {
            e.preventDefault();

            //отправляем данные в локалСторэйдж
            localStorage.setItem("zone", chosenZone);
            localStorage.setItem("phone", phone);
            localStorage.setItem("namer", namer);
            localStorage.setItem("street", street);
            localStorage.setItem("house", house);
            localStorage.setItem("aprts", aprts);
            localStorage.setItem("city", chosenCity);
            //самая простая проверка на наличие обязательных полей
            if (
              namer.length === 0 ||
              phone.length === 0 ||
              street.length === 0 ||
              house.length === 0
            ) {
              alert(
                "Будь ласка, заповніть усі обов'язкові поля (ім'я, номер, вулиця, будинок), інакше замовлення не буде опрацьовано."
              );
              return;
            }
            setOrderSuccess("pending");
            const i = {
              parse_mode: "HTML",
              text: `<b>Клієнт:</b> ${namer}\n<b>Телефон:</b> +${phone}\n\n■ <b>${type.toUpperCase()} в ${chosenCity !== "0"
                ? city[chosenCity].city
                : city[chosenCity].city +
                " (район " +
                zones[chosenZone][0] +
                ")"
                }</b> за <b>адресою:</b> ${street}, <b>будинок:</b> ${house}${aprts ? `<b>, квартира:</b> ${aprts}` : ""
                }${entrance ? `<b>, під'їзд:</b> ${entrance}` : ""}${code ? `<b>, код:</b> ${code}` : ""
                }${floor ? `<b>, поверх:</b> ${floor}` : ""} ${isAsFast
                  ? `\n\n■ <b>Якомога скоріше, будьте ласкаві.</b>`
                  : customDate
                    ? `\n\n■ <b>Замовлення на певний час:</b> ${customDate}`
                    : ``
                } ${wishCall ? `` : "\n\n■ <b>Просять не передзвонювати.</b>"} ${comments
                  ? `\n\n■ <b>Коментар до замовлення:</b> ${comments}`
                  : ""
                } \n\n✪ <b>ЗАГАЛЬНА СУМА замовлення:</b> ${totalPricePlusDelivery} грн, з яких за доставку ${chosenCity === "0"
                  ? zones[chosenZone][1] + " грн"
                  : city[chosenCity].price + " грн"
                } ${messageToTgWithPromo} ${arrOfPizzas}`,
              chat_id: "-1001721175338",
            };

            fetch(
              "https://api.telegram.org/bot5775123731:AAEM6weVVyw5rNROu5H2-_EsU9TtI19utVg/sendMessage",
              {
                method: "POST",

                body: JSON.stringify(i),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .catch((e) => setOrderSuccess("no"))
              .then((res) => {
                if (!res.ok) {
                  console.log(res, "Пошло что-то не так... Попробуйте еще раз");
                  setOrderSuccess("no");
                } else {
                  dispatch(resetAllCart());
                  fetchToUpdateUsedPromo();
                  setTimeout(() => {
                    setOrderSuccess("yes");
                  }, 1500)

                }
              });
          }}
          className="order__submit-btn"
        >
          Зробити замовлення<br></br>без оплати
        </button>
      ) : (
        //ниже кнопка для оплаты онлайн
        <>
          <ButtonPay
            namer={namer}
            phone={phone}
            type={type}
            city={city}
            chosenCity={chosenCity}
            street={street}
            house={house}
            aprts={aprts}
            entrance={entrance}
            code={code}
            floor={floor}
            isAsFast={isAsFast}
            customDate={customDate}
            wishCall={wishCall}
            comments={comments}
            arrOfPizzas={arrOfPizzas}
            cartData={cartData}
            totalPrice={totalPrice}
            totalPricePlusDelivery={totalPricePlusDelivery}
            specialPromoPrice={specialPromoPrice}
            zones={zones}
            chosenZone={chosenZone}
            messageToTgWithPromo={messageToTgWithPromo}
            fetchToUpdateUsedPromo={fetchToUpdateUsedPromo}
          />
        </>
      )}
    </form>
  );
};

export default DeliveryForm;

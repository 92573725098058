import { Link } from "react-router-dom";
import { useState } from "react";
import useMedia768 from "../../../hooks/useMedia";

import authorImg from "../../../assets/constructor/authors800.webp";
import halfsImg from "../../../assets/constructor/halfs.png";
import ImgWithPreloadSpinner from "../../little/ImgWithPreloadSpinner.jsx";

import "./constructor.scss";

const Constructor = () => {

  const isMobile = useMedia768();

  return (
    <>
      {isMobile ? (
        <li className="m-constructor">
          <div className="m-constructor__halfs">
            <ImgWithPreloadSpinner alt={'halfs'} src={halfsImg} className={'m-constructor__halfs-img'} />
            <Link to={`halfs`}>
              <button className="m-constructor__action">
                Зібрати піцу<br></br>з половинок
              </button>
            </Link>
          </div>

          <div className="m-constructor__separator"></div>
          <div className="m-constructor__main">
            <ImgWithPreloadSpinner alt={'constructor'} src={authorImg} className={'m-constructor__main-img'} />
            <Link to={'constructor'}>
              <button className="m-constructor__action">
                Зібрати власну<br></br>авторську піцу
              </button>
            </Link>
          </div>
        </li>
      ) : (
        <li className="pizzablock__pizza-item constructor">
          <div className="constructor__halfs">
            <ImgWithPreloadSpinner alt={'halfs'} src={halfsImg} className={'constructor__halfs-img'} />
            <Link to={`halfs`}>
              <button className="constructor__action">
                Зібрати піцу<br></br>з половинок
              </button>
            </Link>
          </div>

          <hr />
          <div className="constructor__main">
            <ImgWithPreloadSpinner alt={'constructor'} src={authorImg} className={'constructor__main-img'} />
            <Link to={'constructor'}>
              <button className="constructor__action">
                Зібрати власну<br></br>авторську піцу
              </button>
            </Link>
          </div>
        </li>
      )}
    </>
  );
};

export default Constructor;

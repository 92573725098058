import AddIngredients from "../../mod-pizza/add-ingredients/AddIngredients";
import { useOutletContext, Link } from "react-router-dom";

export default function MAuthorsPizzaAdd() {

  const { img, extraIngr, setExtraIngr, priceForOne } = useOutletContext();
  return (
    <div className="m-modal">
      <div className="m-modal__content">

        <Link to='/constructor'>
          <div
            className="m-modal__close"
          >
            ⟵
          </div>
        </Link>

        <div className="m-modal-wr">
          <img src={img} alt="#" className="m-modal__img" />
          <div className="m-modal__wrapper">
            <AddIngredients
              authorsPizza={true}
              priceForOne={priceForOne}
              extraIngr={extraIngr}
              setExtraIngr={setExtraIngr}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

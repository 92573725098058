import './burgerMenu.scss';

import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BurgerMenu = ({ menuBurger, setMenuBurger }) => {

  const activeStyle = { color: "white", borderBottom: "2px solid white" };
  const totalCountCart = useSelector((state) => state.cart.totalCountCart);

  useEffect(() => {
    if (menuBurger) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuBurger]);


  return (
    <>
      <div
        onClick={() => { setMenuBurger(false) }}
        className={`burger-menu ${menuBurger ? 'burger-menu--act' : ''}`}>
      </div>
      <div className={`burger-menu__content ${menuBurger ? 'burger-menu__content--act' : ''}`}>
        <div className="burger-menu__content-hr"> </div>
        <ul className="header__menu header__menu--burger">

          <li><NavLink
            onClick={() => { setMenuBurger(false) }}
            to="/"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Головна (вітрина)</NavLink></li>
          <li><NavLink
            onClick={() => { setMenuBurger(false) }}
            to="/about"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Про заклад</NavLink></li>
          <li><NavLink
            onClick={() => { setMenuBurger(false) }}
            to="/promotion"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Акції</NavLink></li>
          <li><NavLink
            onClick={() => { setMenuBurger(false) }}
            to="/legend"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Наша легенда</NavLink></li>
          <li><NavLink
            onClick={() => { setMenuBurger(false) }}
            to="/cart"
            style={({ isActive }) =>
              isActive ? activeStyle : undefined
            }>Кошик ({totalCountCart})</NavLink></li>

        </ul>
      </div>
    </>
  )

}

export default BurgerMenu;

import './drinkBlock.scss';

import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMedia768 from '../../hooks/useMedia';
import { useSelector } from 'react-redux/es/exports';
import MSkeleton from '../skeleton/MSkeleton';
import Skeleton from '../skeleton/Skeleton';
import DrinksItem from './DrinksItem';
const DrinkBlock = () => {
  const navigatorToDrinks = useRef(null);
  const location = useLocation();


  const drinksData = useSelector(state => state.general.drinks)
  const loadingDrinks = useSelector(state => state.general.loadingDrinks);
  //ниже моя уродливая проверка на то, получены ли элементы
  const loadingBorts = useSelector(state => state.general.loadingBorts);
  const loadingCatOfIngred = useSelector(state => state.general.loadingCatOfIngred);
  const loadingIngr = useSelector(state => state.general.loadingIngr);
  const pizzaLoadingStatus = useSelector((state) => state.general.pizzaLoadingStatus);
  //по уродливой проверке - я их вітянул, чтоб напитки не появлялись раньше пиццаблоков, но они все равно появляются
  const isMobile = useMedia768();
  const [isMounted, setIsMounted] = useState(false);
  //я поставил задерку, чтобі контент успел отрисоваться и можно біло прокручивать страницу. Привязались мы к пиццаЛоадингстатус - понятно зачем, а к location - для того, чтобі при каждом клике, даже если хеш === дринкс, все равно срабатывал юзЭффект. Видимо приходит новая ссылка на объект... Чтобы два раза страница не прокручивалась после перезагрузки (при первом маунтинге). Я сделал так, что работать юзЭффект будет только после маунтинга, но не во время его.
  //КАк видишь, добавил еще одно єлсє иф. Потому что пиццалоадингстатус не обновляется если мы переходим с другого роута (он "ок"), а первый маунтинг пропускается - значит не будет проктрутки. Я сделал условие, что если даже это первый маунтинг, но пиццалоадингстатус = ок, то делаем прокрутку. Работает....
  useEffect(() => {
    if (isMounted) {
      if (location.hash === '#drinks') {
        setTimeout(() => {
          navigatorToDrinks.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 50)
      }
    }
    else if (!isMounted && pizzaLoadingStatus === 'ok') {
      if (location.hash === '#drinks') {
        setTimeout(() => {
          navigatorToDrinks.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 40)
      }
    }
    if (!isMounted) setIsMounted(true);
  }, [location, pizzaLoadingStatus === 'ok']);
  //

  let content;
  if (loadingDrinks !== "ok" || pizzaLoadingStatus !== "ok" || loadingBorts !== 'ok' || loadingIngr !== 'ok' || loadingCatOfIngred !== 'ok') {
    content = (
      <div className="pizzablock__pizza">
        <ul className="pizzablock__pizza-items">
          {isMobile ? (
            <>
              {[1, 2, 3, 4].map((item) => <MSkeleton key={item} />)}

            </>
          ) : (
            <>
              {[1, 2, 3, 4].map((item) => <Skeleton key={item} />)}
            </>
          )}
        </ul>
      </div>
    );

  }
  //если ошибка - ошибка
  /*  else if (loadingDrinks === "error") {
     content = "Сталася якась помилка, спробуйте пізніше...";
   } */
  else if (loadingDrinks === "ok") {
    const items = drinksData.map(item => {
      return <DrinksItem key={item.id} info={item} />
    })
    content = (
      <div id="pizzalist" className="pizzablock__pizza">
        <ul className="pizzablock__pizza-items">
          {items}
        </ul>
      </div>
    )
  }



  return (
    <>
      <div className='kind-separator'></div>
      <div className="drinkblock" ref={navigatorToDrinks}>

        <h2 className="pizzablock__title drinkblock__title" >НАПОЇ</h2>
        {content}


      </div>
    </>
  )
}

export default DrinkBlock;

import '../pages.scss';
import './promotion.scss';

import ImgWithPreloadSpinner from '../../components/little/ImgWithPreloadSpinner';

import coupon from '../../assets/promotion/coupon450.webp';
import freeday from '../../assets/promotion/freeday450.webp';
import promocode from '../../assets/promotion/promocode450.webp';

import { useEffect } from 'react';

const Promotion = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="promotion">
      <div className="container">
        <h1 className="pages__title">
          Акції
        </h1>
        <div className="promotion__block">
          <ImgWithPreloadSpinner src={coupon} alt="coupons" className="pages__img promotion__img" />
          <div className="promotion__content">
            <h2 className="promotion__title">Збери 10 купонів та отримай піцу безкоштовно</h2>
            <div className="promotion__descr">
              Cтрок дії акції - безстрокова<br /><br />
            </div>
            <div className="promotion__descr">
              Умови акції:
            </div>
            <div className="promotion__text">Купуєте піцу – зберігаєте купони.<br /><br />
              Зібравши 10 купонів, особа має право прийти безпосередньо до закладу М2Т (ТЦ «Добропілля») та обміняти відповідну кількість купонів на будь-яку піцу, яка є в асортименті.
              <br /><br />Якщо ж особа бажає замовити доставку, то до «безкоштовної» піци потрібно буде замовити хоча б одну піцу додатково.
              <br /><br />
              Варто звернути увагу, що купон знаходиться на бічній стороні фірмової коробки для піци.
            </div>
          </div>
        </div>

        <div className="promotion__separator"></div>

        <div className="promotion__block">
          <ImgWithPreloadSpinner src={freeday} alt="coupons" className="pages__img promotion__img" />
          <div className="promotion__content">
            <h2 className="promotion__title">Дні безкоштовної доставки</h2>
            <div className="promotion__descr">
              Cтрок дії акції - оголошується додатково на один чи кілька конкретних днів<br /><br />
            </div>
            <div className="promotion__descr">
              Умови акції:
            </div>
            <div className="promotion__text">Якщо у звичайні дні наша доставка є платною, то в дні безкоштовної доставки будь-яке замовлення буде доставлено безкоштовно. Отже потрібно буде заплатити лише за сам замовлений продукт. =)
              <br /><br />
              Про акцію може бути оголошено зненацька, тому радимо підписатися на наш <a className='promotion__smlink' target='_blank' href='https://www.instagram.com/m2t.pizza/'>Інстаграм @m2t.pizza</a>, щоб бути в курсі всіх наших івентів.
            </div>
          </div>
        </div>

        <div className="promotion__separator"></div>

        <div className="promotion__block">
          <ImgWithPreloadSpinner src={promocode} alt="coupons" className="pages__img promotion__img" />
          <div className="promotion__content">
            <h2 className="promotion__title">Промокоди на знижку</h2>
            <div className="promotion__descr">
              Cтрок дії акції - оголошується додатково на один чи кілька конкретних днів<br /><br />
            </div>
            <div className="promotion__descr">
              Умови акції:
            </div>
            <div className="promotion__text">Час від часу ми випускаємо спеціальні промокоди у формі певних тематичних слів. Наприклад - "m2t", "pepperoni" тощо.
              <br /><br />
              Якщо вписати акутальний промокод у спеціальу форму на сайті й активувати, то застосується певна процентна знижка на всю суму замовлення. Приємна вигода.)
              <br /><br />
              Варто мати на увазі, що задіяти промокод можливо лише через сайт піцерії.
              <br /><br />
              Про появу чергового промокоду може бути оголошено зненацька, тому радимо підписатися на наш <a className='promotion__smlink' target='_blank' href='https://www.instagram.com/m2t.pizza/'>Інстаграм @m2t.pizza</a>, щоб точно не пропустити таку новину.
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Promotion;

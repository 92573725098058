import { useEffect, useState } from "react";

const useExtrasPriceSum = (extraIngr) => {
  //поскольку у нас есть дополнительные ингредиенты и есть счетчик кол-ва пицц, нам нужно модифицировать цену перед отправкой.
  //я захотел, чтобы при каждом рендеринге не просчитывалась сумма цен всех дополнительных ингредиентов, поэтому сделал зависимость от обновления РЕАКТ СТЭЙТА (объекта с добавленными ингредиентами). Когда в него вносятся изменения, будет происходить пересчет суммы. С одной стороны я сделал то, что хотел. Но, с другой стороны, компонент рендерится теперь два раза, потому что меняется стэйт дополнительных ингредиентов (рендер), затем реакт понимает, что произошли изменения и вызывает юзЭффект, где просчитывается сумма и вновь меняется стэйт, но уже extrasPriceSum (рендер). Надо будет обратить на это еще внимание.
  const [extrasPriceSum, setExtrasPriceSum] = useState(0);
  useEffect(() => {
    setExtrasPriceSum(totalPriceExtrasSum(extraIngr));
  }, [extraIngr]);

  //функция для подсчета суммы стоимости всех добавленных ингриедиентов (подробнее она расписана в cartSlice)
  function totalPriceExtrasSum(state) {
    if (Object.keys(state).length === 0) {
      return 0;
    }
    let arrWithTotalPrices = Object.keys(state).map((item) =>
      state[item].map((item) => item.price)
    );
    const totalPrice = arrWithTotalPrices
      .reduce((previousValue, currentValue) => {
        return previousValue.concat(currentValue);
      })
      .reduce((a, b) => a + b);
    return totalPrice;
  }

  return extrasPriceSum;
};

export default useExtrasPriceSum;

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetAllCart, deleteActivePromoCode } from "../../../features/cartSlice";

import ButtonPay from "./ButtonPay";
import PromoBlock from "../../little/promoBlock/PromoBlock";

/* import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { setHours, setMinutes }from 'date-fns';
import uk from 'date-fns/locale/uk';
import "react-datepicker/dist/react-datepicker.css"; */
import PhoneInput from "react-phone-input-2";

import { useSelector } from "react-redux";

/* import {Day, Time} from './DataPickers' */

const SelfServiceForm = ({ type, setOrderSuccess, canPay }) => {
  const dispatch = useDispatch();

  const [namer, setNamer] = useState(localStorage.getItem("namer") || "");

  const refName = useRef(0);

  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [comments, setComments] = useState("");
  const [customDate, setCustomDate] = useState("");

  //если эсФаст тру, значит доставка нужна как можно быстрее, иначе - на конкретное время и дату - появляется соответствующие инпуты (опять же условный рендеринг)
  const [isAsFast, setIsAsFast] = useState(true);
  //далее функционал тоже для условного рендеринга, платить после доставки или онлайн
  const [payAfter, setPayAfter] = useState(true);

  const [specialPromoPrice, setSpecialPromoPrice] = useState(null);

  //получим конечную стоимость для отображения перед кнопкой заказа
  const totalPrice = useSelector((state) => state.cart.totalPriceCart);
  const activePromocode = useSelector(state => state.cart.activePromocode);

  useEffect(() => {
    if (activePromocode?.formating === 'percent') {
      setSpecialPromoPrice(+((totalPrice * activePromocode.percent_to_math).toFixed(1)))
    }
    if (activePromocode?.formating === 'cash') {
      const techno = +((totalPrice - activePromocode.cash).toFixed(1));
      setSpecialPromoPrice(techno < 0 ? 0 : techno);
    }
  }, [activePromocode?.formating, totalPrice])




  //ниже решение такое себе...
  //ниже таймЮА - по умолчанию сделал его как время открытия заведения. Здесь у нас создается новый экземпляр объекта ДАТА с кастомными часами и минутами.
  //формат такой: Sun Sep 04 2022 15:45:00 GMT+0300 (Восточная Европа, летнее время)
  //такое поведение понадобится в компонентах РЕАКТ-ДАТАПИКЕРА
  /*     const [timeUa, setTimeUa] = useState(setHours(setMinutes(new Date(), 0), 10))
   */

  //ниже при монтировании компонента я делаю запрос, чтобы получить время по Киеву.
  //получаю объект в ключе DATETIME которого хранится значение: '2022-09-04T13:59:56.063025+03:00'
  //однако РЕАКТ-ДАТАПИКЕР работает, только с таким форматом Sun Sep 04 2022 15:45:00 GMT+0300 (Восточная Европа, летнее время)
  //поєтому мы снова обращаемся к конструтору ДАТЫ и записываем необходимое значение в СТЭЙТ. Теперь изначально указанное время- текущее время.
  //записіваю в СТЭЙТ этого компонента, чтобы затем ПРОПСАМИ передать его в два компонента РЕАКТ-ДАТАПИКЕРА (ТАЙМ и ДЭЙТ)
  /*  useEffect(()=>{
        fetch('http://worldtimeapi.org/api/timezone/Europe/Kiev')
        .then(res=>res.json())
        .then(res=>setTimeUa(new Date(res.datetime)));

        //ниже при монтировании компонента зарегистрировал украинскую локализацию для РЕАКТ-ДАТАПИКЕРА
        registerLocale('uk', uk)
        setDefaultLocale('uk')
    }, [isAsFast]) */
  //выше решение такое себе...

  //далее функционад для понимания - нужно ли перезванивать
  const [wishCall, setWishCall] = useState(true);

  //надо же отправлять данные из корзины...
  const cartData = useSelector((state) => state.cart.cart);
  const ingr = useSelector((state) => state.general.ingr);
  const loadingIngr = useSelector((state) => state.general.loadingIngr);

  if (loadingIngr !== "ok") {
    return null;
  }
  const arrOfPizzas = Object.values(cartData).map((item) => {
    const { polo, kind } = item[0];

    if (polo) {
      const { extraIngr, extraIngrFirst, extraIngrSecond } = item[0].extra;
      const { deletedIngrForOne, deletedIngrForTwo } = item[0].deleted;
      const { namePoloOne, namePoloTwo } = item[0].name;

      const deletedFromOne = Object.keys(deletedIngrForOne).map(
        (item) => `(${ingr[item][0]})`
      );
      const deletedFromTwo = Object.keys(deletedIngrForTwo).map(
        (item) => `(${ingr[item][0]})`
      );

      const extraAll = Object.keys(extraIngr).map((item) => {
        return `(${ingr[item][0]} - x${extraIngr[item].length})`;
      });
      const extraFirst = Object.keys(extraIngrFirst).map((item) => {
        return `(${ingr[item][0]} - x${extraIngrFirst[item].length})`;
      });
      const extraSecond = Object.keys(extraIngrSecond).map((item) => {
        return `(${ingr[item][0]} - x${extraIngrSecond[item].length})`;
      });

      return `\n\n➤ <b>х${item.length
        } - ${namePoloOne.toUpperCase()}+${namePoloTwo.toUpperCase()}</b> - ${item[0].price * item.length
        } грн ||| <b>борт ${item[0].bortName}</b> ${deletedFromOne.length !== 0
          ? `||| <b>видалено з ${namePoloOne}:</b> ${deletedFromOne}`
          : ``
        } ${deletedFromTwo.length !== 0
          ? `||| <b>видалено з ${namePoloTwo}:</b> ${deletedFromTwo}`
          : ``
        } ${extraAll.length !== 0
          ? `||| <b>додано до всієї піци:</b> ${extraAll}`
          : ``
        } ${extraFirst.length !== 0
          ? `||| <b>додано до ${namePoloOne}:</b> ${extraFirst}`
          : ``
        } ${extraSecond.length !== 0
          ? `||| <b>додано до ${namePoloTwo}:</b> ${extraSecond}`
          : ``
        }`;
    }
    else if (kind === 'drinks') {
      return `\n\n➤ <b>х${item.length} - ${item[0].name.toUpperCase()}</b> <b>(${item[0].volume} л)</b> - ${item[0].price * item.length} грн`;
    }
    else {
      const { extra } = item[0];

      const deleted = Object.keys(item[0].deleted).map(
        (item) => `(${ingr[item][0]})`
      );
      const extras = Object.keys(extra).map((item) => {
        return `(${ingr[item][0]} - x${extra[item].length})`;
      });
      return `\n\n➤ <b>х${item.length} - ${item[0].name.toUpperCase()}</b> - ${item[0].price * item.length
        } грн ||| <b>борт ${item[0].bortName}</b> ${deleted.length !== 0 ? `||| <b>видалено з піци:</b> ${deleted}` : ``
        } ${extras.length !== 0 ? `||| <b>додано до піци:</b> ${extras}` : ``}`;
    }
  });
  //
  const messageToTgWithPromo = `${activePromocode?.title ? `\n\n⚠️ Увага! Задіяно промо-код "${activePromocode.title}", який дає скидку ${activePromocode.percent ? activePromocode.percent : `${activePromocode.cash} грн.`} Остаточна ціна ${specialPromoPrice} грн.` : ""}`;

  const fetchToUpdateUsedPromo = async () => {
    if (activePromocode?.title) {
      await fetch('http://localhost:5001/update-used', {
        method: 'POST',
        body: JSON.stringify({ title: activePromocode.title })
      })
        .then(res => {
          if (!res.ok) {
            console.log('problem with fetch update-used')
          }
        }).catch(err => console.log(err))

      document.cookie = 'promoTitle=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    }
  }

  return (
    <form className="delivery" action="">
      <fieldset className="delivery__contact-fieldset">
        <legend className="delivery__contact-legend">
          Контактна інформація
        </legend>

        <div>
          <label className="delivery-label-star" htmlFor="namer">
            ім'я
          </label>
          <input
            ref={refName}
            className="delivery__contact-name"
            id="namer"
            name="namer"
            type="text"
            value={namer}
            onChange={(e) => {
              setNamer(e.target.value);
            }}
          />
        </div>

        <div className="delivery__contact-phone-wrapper">
          <label className="delivery-label-star">тел.</label>
          <PhoneInput
            inputClass={"delivery__contact-phone"}
            placeholder={"Телефон"}
            disableDropdown={true}
            country={"ua"}
            countryCodeEditable={false}
            onlyCountries={["ua"]}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
        </div>

        {/*  <input
             onChange={(e)=>{setPhone(e.target.value)}}
            className="delivery__contact-phone" name="phone" type="text" placeholder="Телефон"value={phone} /> */}
      </fieldset>

      <fieldset className="delivery__data-fieldset">
        <legend className="delivery__data-legend">Час та дата</legend>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setIsAsFast(true);
            }}
            className="asFast"
          >
            <span
              className={isAsFast ? "custom-radio checked" : "custom-radio"}
            ></span>
            Якомога швидше
          </div>
          <div
            onClick={() => {
              setIsAsFast(false);
            }}
            className="onTheData"
          >
            <span
              className={isAsFast ? "custom-radio" : "custom-radio checked"}
            ></span>
            На певний час / дату
          </div>
        </div>

        {isAsFast ? (
          <p className="order__time-info">
            *Ми намагатимося привезти ваше замовлення настільки швидко,
            наскільки це можливо з урахуванням актуальної черги.
          </p>
        ) : (
          /* <div style={{marginTop:'15px', display: 'flex'}}>
                <Time timeUa={timeUa}/>
                <Day timeUa={timeUa}/>
                </div> */
          <textarea
            value={customDate}
            onChange={(e) => {
              setCustomDate(e.target.value);
            }}
            className="order__custom-date"
            name="date"
            placeholder='Наприклад: "сьогодні о 15:00..."'
          ></textarea>
        )}
      </fieldset>

      <fieldset className="delivery__pay-fieldset">
        <legend className="delivery__pay-label">Оплата</legend>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setPayAfter(true);
            }}
            className="asFast"
          >
            <span
              className={payAfter ? "custom-radio checked" : "custom-radio"}
            ></span>
            Оплата при отриманні
          </div>

          {/* ниже налепил для отключения оплаты функционал */}
          {canPay === "yes" ? (
            <div
              onClick={() => {
                setPayAfter(false);
              }}
              className="onTheData"
            >
              <span
                className={payAfter ? "custom-radio" : "custom-radio checked"}
              ></span>
              Оплата online
            </div>
          ) : null}
        </div>
        {payAfter ? (
          <p className="order__pay-info">
            *Ви зможете оплатити своє замовлення безпосередньо після його
            отримання. <br></br>Наш драйвер прйме оплату готівкою або через
            безготівковий розрахунок, використовуючи банківський міні-термінал.
          </p>
        ) : null}
      </fieldset>

      <fieldset className="order__add-fieldset">
        <legend className="order__add-label">Додаткова інформація</legend>
        <p className="order__ask-for-call">
          Передзвонити для уточнення замовлення?
        </p>
        <div className="delivery__data-choose-wrapper">
          {/* использую ниже условный рендеринг для того, чтобы отмечать активный выбор */}
          <div
            onClick={() => {
              setWishCall(true);
            }}
            className="asFast"
          >
            <span
              className={wishCall ? "custom-radio checked" : "custom-radio"}
            ></span>
            Так
          </div>
          <div
            onClick={() => {
              setWishCall(false);
            }}
            className="onTheData"
          >
            <span
              className={!wishCall ? "custom-radio checked" : "custom-radio"}
            ></span>
            Ні
          </div>
        </div>
        {wishCall ? null : (
          <p className="delivery-note-about-call">
            Але якщо з вашим замовленням виникнуть труднощі, ми всеодно муситимо
            вам подзовнити.
          </p>
        )}
        <p className="order__message-todel">Коментар до служби доставки</p>
        <textarea
          value={comments}
          onChange={(e) => {
            setComments(e.target.value);
          }}
          className="order__comments"
          name="comments"
          placeholder="залиште повідомлення у цьому текстовому полі, якщо бажаєте..."
        ></textarea>
      </fieldset>
      <div
        style={activePromocode ? { 'textDecoration': "line-through" } : null}
        className="order__form-totalPrice">
        Вартість замовлення - {totalPrice} грн
      </div>

      <PromoBlock specialPromoPrice={specialPromoPrice} />
      {/* ниже будет проверка. Если сумма нулевая, то вместо кнопки подтверждения заказа - мы будем показывать надпись */}
      {totalPrice == 0 ? (
        <div className="order__attention-empty">
          Аби зробити замовлення, додайте товар до кошика...
        </div>
      ) : payAfter ? (
        <button
          onClick={(e) => {
            e.preventDefault();

            //отправляем данные в локалСторэйдж
            localStorage.setItem("phone", phone);
            localStorage.setItem("namer", namer);

            //самая простая проверка на наличие обязательных полей
            if (namer.length === 0 || phone.length === 0) {
              alert(
                "Будь ласка, заповніть усі обов'язкові поля (ім'я, номер), інакше замовлення не буде опрацьовано."
              );
              return;
            }
            setOrderSuccess("pending");
            const i = {
              parse_mode: "HTML",
              text: `<b>Клієнт:</b> ${namer}\n<b>Телефон:</b> +${phone}\n\n■ <b>${type.toUpperCase()}</b>${isAsFast
                ? `\n\n■ <b>Якомога скоріше, будьте ласкаві.</b>`
                : customDate
                  ? `\n\n■ <b>Замовлення на певний час:</b> ${customDate}`
                  : ``
                } ${wishCall ? `` : "\n\n■ <b>Просять не передзвонювати</b>"} ${comments
                  ? `\n\n■ <b>Коментар до замовлення:</b> ${comments}`
                  : ""
                } \n\n✪ <b>ЗАГАЛЬНА СУМА замовлення:</b> ${totalPrice} грн ${messageToTgWithPromo} ${arrOfPizzas}`,
              chat_id: "-1001721175338",
            };

            fetch(
              "https://api.telegram.org/bot5775123731:AAEM6weVVyw5rNROu5H2-_EsU9TtI19utVg/sendMessage",
              {
                method: "POST",

                body: JSON.stringify(i),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .catch((e) => setOrderSuccess("no"))
              .then((res) => {
                if (!res.ok) {
                  console.log(res, "Пошло что-то не так... Попробуйте еще раз");
                  setOrderSuccess("no");
                } else {
                  dispatch(resetAllCart());
                  fetchToUpdateUsedPromo();
                  setTimeout(() => {
                    setOrderSuccess("yes");
                  }, 1500)
                }
              });
          }}
          className="order__submit-btn"
        >
          Зробити замовлення<br></br>без оплати
        </button>
      ) : (
        <ButtonPay
          namer={namer}
          phone={phone}
          type={type}
          isAsFast={isAsFast}
          customDate={customDate}
          wishCall={wishCall}
          comments={comments}
          arrOfPizzas={arrOfPizzas}
          cartData={cartData}
          totalPrice={totalPrice}
          specialPromoPrice={specialPromoPrice}
          messageToTgWithPromo={messageToTgWithPromo}
          fetchToUpdateUsedPromo={fetchToUpdateUsedPromo}
        />
      )}
    </form>
  );
};

export default SelfServiceForm;
